import Logo from '@components/UI/Decorators/Event-Rulers/Logo';
import { useMediaQuery } from '@hooks/index';
import useScroll from '@hooks/useScroll';
import { KeyboardArrowDown } from '@mui/icons-material';
import { AppBar, Stack, Toolbar, useTheme } from '@mui/material';

import { useAppDispatch, useAppSelector } from '@store/hooks';

import { GlobalStateActions } from '@store/slices/globalStateReducer';
import { snackbarAction } from '@store/slices/snackbarReducer';
import { userLogout } from '@store/slices/userReducer';
import React, { Suspense, lazy } from 'react';

import { useCallback, useEffect, useMemo } from 'react';

import { useLocation } from 'react-router-dom';

import { Loader, navigation } from '..';

import classes from './style.module.scss';

const BurgerMenu = lazy(() => {
  return import('./burger-menu/BurgerMenu');
});
const MobileMenu = lazy(() => {
  return import('./mobile-menu/MobileMenu');
});

const DropdownMenu = lazy(() => {
  return import('./nav-link/DropdownMenu');
});

const NavLink = lazy(() => {
  return import('./nav-link/NavLink');
});

const Navbar = () => {
  const dispatch = useAppDispatch();
  const draverIsOpen = useAppSelector((state) => {
    return state.globalState.menuDrawerIsOpen;
  });
  const isLogin = useAppSelector((state) => {
    return !!state.user.user_token;
  });

  const isMobile = useMediaQuery('lg');
  const scrolled = useScroll(100);
  const theme = useTheme();
  const location = useLocation();
  const currentPath = location.pathname;

  const handleLogoClick = useCallback(() => {
    dispatch(GlobalStateActions.drawerFunc(false));
  }, [dispatch]);

  useEffect(() => {
    if (!isMobile && draverIsOpen) {
      handleLogoClick();
    }
  }, [handleLogoClick, isMobile, draverIsOpen]);

  const logoutHandler = useCallback(() => {
    dispatch(userLogout());
    dispatch(
      snackbarAction({ message: 'Zostałeś wylogowany', status: 'edited' })
    );
    localStorage.removeItem('user');
  }, [dispatch]);

  const appBarStyle = useMemo(() => {
    return {
      backgroundColor:
        scrolled || draverIsOpen
          ? theme.palette.userColors['--background-color-soft']
          : 'transparent',
      transition: 'all 0.3s ease',
      height: scrolled || draverIsOpen ? 'initial' : '110px',
      boxShadow: scrolled || draverIsOpen ? theme.shadows[4] : 'none',
    };
  }, [scrolled, draverIsOpen, theme]);

  return (
    <AppBar position="fixed" style={appBarStyle}>
      <Toolbar className={classes.toolbar}>
        <Suspense fallback={<Loader />}>
          <Logo
            color="--main-gold"
            aria-label="logo"
            zIndex={150}
            duration={scrolled ? 0.5 : 1}
            delay={scrolled ? 0.1 : 0.3}
            height={scrolled ? (isMobile ? 50 : 60) : isMobile ? 60 : 70}
            to="/"
            onClick={handleLogoClick}
          />
          {isMobile ? (
            <BurgerMenu />
          ) : (
            <nav>
              <Stack direction="row" component="ul">
                {navigation.map((item) => {
                  return (
                    <li key={item.path} className={classes['list-item']}>
                      <NavLink
                        className={classes.link}
                        to={item.path}
                        color="--white-shadow"
                        size="x-small"
                        fontFamily="barlow-italic-bold"
                      >
                        {item.name}
                      </NavLink>
                      {item.subMenu && (
                        <>
                          <KeyboardArrowDown />
                          <DropdownMenu
                            subMenu={item.subMenu}
                            parentPath={item.path}
                            color="--background-color-soft"
                            hoverColor="--main-gold"
                          />
                        </>
                      )}
                    </li>
                  );
                })}
                {isLogin && (
                  <li className={classes['list-item']} onClick={logoutHandler}>
                    <NavLink
                      to={currentPath}
                      color="--white-shadow"
                      size="x-small"
                      fontFamily="barlow-italic-bold"
                      isLogout
                    >
                      Wyloguj
                    </NavLink>
                  </li>
                )}
              </Stack>
            </nav>
          )}
        </Suspense>
      </Toolbar>
      {draverIsOpen && <MobileMenu />}
    </AppBar>
  );
};

export default Navbar;
