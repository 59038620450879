import clsx from 'clsx';
import { Puff } from 'react-loader-spinner';

import { CustomColors } from '../types';

import classes from './loader.module.scss';

interface LoaderProps {
  color?: CustomColors;
  className?: string;
  height?: number;
  width?: number;
  fullWidth?: boolean;
}

export const Loader = ({
  color = '--main-gold',
  className,
  width = 50,
  fullWidth,
}: LoaderProps) => {
  return (
    <div
      className={clsx(className, classes.spinner, {
        [classes['full-width']]: fullWidth,
      })}
    >
      <Puff color={`var(${color})`} height={width} width={width} />
    </div>
  );
};
