import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import React, { FC } from 'react';

import classes from './controll-buttons.module.scss';

import { ButtonControlsProps } from './types';

export const PrevButton: FC<ButtonControlsProps> = ({ elementRef }) => {
  return (
    <div ref={elementRef} className={classes.button}>
      <ArrowBackIosIcon className={classes.arrow} />
    </div>
  );
};

export const NextButton: FC<ButtonControlsProps> = ({ elementRef }) => {
  return (
    <div ref={elementRef} className={classes.button}>
      <ArrowForwardIosIcon className={classes.arrow} />
    </div>
  );
};
