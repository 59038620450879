/* eslint-disable no-unused-vars */
import { SxProps, Theme, Typography, css, styled } from '@mui/material';

import { TypographyProps } from '@mui/material/Typography/Typography';

import { fontSizeMap } from '../styled';
import { AlignType, CustomColors, FontFamilies, SizesType } from '../types';

export interface TypographyStyleProps extends TypographyProps {
  color?: CustomColors;
  size?:
    | SizesType
    | { [key in keyof Theme['breakpoints']['values']]?: SizesType };
  fontFamily?: FontFamilies;
  textAlign?: AlignType;
  uppercase?: boolean;
  sx?: SxProps<Theme>;
  underline?: boolean;
}

const responsiveFontSize = (
  size: TypographyStyleProps['size'],
  theme: Theme,
  color: CustomColors,
  uppercase: boolean,
  underline: boolean
) => {
  const baseStyles = css`
    color: var(${color});
    text-transform: ${uppercase ? 'uppercase' : 'inherit'};
    text-decoration: ${underline ? 'underline' : 'inherit'};
  `;

  if (typeof size === 'object') {
    // Ustawia rozmiary czcionek dla poszczególnych breakpointów
    const sizeStyles = css`
      ${Object.entries(size)
        .map(([breakpoint, value]) => {
          return `
          ${theme.breakpoints.only(
            breakpoint as keyof Theme['breakpoints']['values']
          )} {
            font-size: ${fontSizeMap[value] || fontSizeMap.medium};
          }
        `;
        })
        .join('')}
    `;

    // Łączy style bazowe i style rozmiarów
    return css`
      ${baseStyles}
      ${sizeStyles}
    `;
  }

  // Dla pojedynczych rozmiarów zwraca style bazowe i ustawia font-size
  return css`
    ${baseStyles}
    font-size: ${fontSizeMap[size as SizesType] || fontSizeMap.medium};
  `;
};

export const TypographyStyled = styled(Typography, {
  shouldForwardProp: (prop) => {
    return (
      prop !== 'size' &&
      prop !== 'uppercase' &&
      prop !== 'underline' &&
      prop !== 'color' &&
      prop !== 'onClick'
    );
  },
})<TypographyStyleProps>(({
  content,
  theme,
  color = '--black',
  size,
  uppercase = false,
  underline = false,
  ...props
}) => {
  return {
    content,
    ...responsiveFontSize(size, theme, color, uppercase, underline),
    ...props,
  };
});
