import React, { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { LinkComponentProps } from './Link.types';

// Definiujemy ref do obiektu HTMLAnchorElement, ponieważ jest to natywny element HTML.
export const LinkComponent = forwardRef<HTMLAnchorElement, LinkComponentProps>(
  (
    {
      children,
      to,
      style,
      underline = false,
      padding = 0,
      margin = 'inherit',
      color,
      fontFamily,
      letterSpacing,
      label,
      ...props
    },
    ref
  ) => {
    const isExternalLink =
      /^https?:\/\//.test(to.toString()) ||
      /^mailto:/.test(to.toString()) ||
      /^tel:/.test(to.toString());

    const composedStyle = {
      ...style,
      padding,
      margin,
      textDecoration: underline ? 'underline' : 'none',
      color: `var(${color})`,
      fontFamily,
      letterSpacing,
      linkStyle: 'none',
    };

    if (isExternalLink) {
      return (
        <a
          style={composedStyle}
          href={to as string}
          ref={ref}
          {...props}
          aria-label={label}
        >
          {children}
        </a>
      );
    } else {
      // Dla RouterLink, ref nie jest potrzebny, chyba że RouterLink eksponuje jego używanie
      return (
        <RouterLink style={composedStyle} to={to as string} {...props}>
          {children}
        </RouterLink>
      );
    }
  }
);

LinkComponent.displayName = 'LinkComponent';
