import { Modal, ModalProps } from '@mui/material';
import React, { useRef } from 'react';

import classes from './modal.module.scss';

export const ModalWrapper = ({ children, open, ...rest }: ModalProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  return (
    <Modal open={open} className={classes.modal} ref={wrapperRef} {...rest}>
      {children}
    </Modal>
  );
};

export default ModalWrapper;
