import { Socials } from '@components/socials/Socials';
import {
  Typography,
  Divider,
  Box,
  IconButton,
  LinkComponent,
} from '@components/UI';
import { useMobile } from '@hooks/useMobile';

import React from 'react';

import classes from './footer.module.scss';

export const Footer = () => {
  const isMobile = useMobile();

  return (
    <section className={classes.footer}>
      <Divider variant="fullWidth" color="--main-gold" />
      <Typography
        component="p"
        fontFamily="barlow-italic"
        color="--white-shadow"
        letterSpacing={2}
        textAlign="center"
        m={isMobile ? 4 : 6}
        size={isMobile ? 'x-small' : 'small'}
      >
        Event Rulers oferuje topowych DJów, konferansjerów oraz muzyków na
        wesele.
      </Typography>
      <Box>
        <Typography
          component="h2"
          fontFamily="barlow-italic-bold"
          textAlign="center"
          color="--white-shadow"
          letterSpacing={2}
          mb={2}
          size={{
            xs: 'small',
            sm: 'medium',
            md: 'large',
            lg: 'x-large',
            xl: 'x-large',
          }}
        >
          Kontakt
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center" mb={1}>
        <IconButton
          variant="email"
          to="mailto: biuro@eventrulers.pl"
          size={isMobile ? 'small' : 'medium'}
          color="--main-gold"
          m={0.5}
          mr={2}
          aria-label="email"
        />
        <LinkComponent
          color="--white-shadow"
          fontFamily="barlow-italic-bold"
          letterSpacing={1}
          to="mailto: biuro@eventrulers.pl"
          label="email"
        >
          biuro@eventrulers.pl
        </LinkComponent>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center">
        <IconButton
          variant="phone"
          to="tel: +48698212279"
          size={isMobile ? 'medium' : 'large'}
          color="--main-gold"
          m={0.5}
          mr={2}
          aria-label="telephone"
        />
        <LinkComponent
          color="--white-shadow"
          fontFamily="barlow-italic-bold"
          letterSpacing={1}
          to="tel: +48698212279"
          label="telephone"
        >
          +48 698-212-279
        </LinkComponent>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Socials color="--main-gold" size={isMobile ? 'x-large' : 'xx-large'} />
      </Box>
      <div
        className="fb-like"
        data-share="true"
        data-width="450"
        data-show-faces="true"
      >
        {' '}
      </div>
      <BottomTimer />
    </section>
  );
};

export const BottomTimer = () => {
  const isMobile = useMobile();
  const date = new Date().getFullYear();
  return (
    <>
      <Divider variant="fullWidth" color="--main-gold" />
      <Box
        component="div"
        width="300px"
        height="30px"
        margin="8px auto"
        display="flex"
        alignItems="flex-end"
        justifyContent="center"
        color="--main-gold"
      >
        <Typography
          component="p"
          size={isMobile ? 'xx-small' : 'small'}
          fontFamily="barlow-italic"
          color="--white-shadow"
          letterSpacing={1}
        >
          Copyright&copy; 2020-{`${date}`} RULERS GROUP
        </Typography>
      </Box>
    </>
  );
};
