import { NavigationItem } from '@components/navbar';
import NavLink from '@components/navbar/nav-link/NavLink';
import { Divider } from '@components/UI';
import { ArrowDropDown } from '@mui/icons-material';
import { List, ListItem, ListItemButton, ListItemIcon } from '@mui/material';
import { useAppDispatch } from '@store/hooks';
import { GlobalStateActions } from '@store/slices/globalStateReducer';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import React, { FC, memo, useState } from 'react';

import classes from '../../style.module.scss';

const MobileDropdownMenu = ({
  onClick,
  name,
  path,
}: {
  name: NavigationItem['name'];
  path: NavigationItem['path'];
  onClick: () => void;
}) => {
  return (
    <ListItem>
      <ListItemButton
        className={classes['mobile__dropdown-button']}
        sx={{
          backgroundColor: 'var(--pure-white-shadow)',
        }}
      >
        <div
          style={{
            flex: 1,
            textAlign: 'center',
            position: 'relative',
          }}
        >
          <NavLink
            className={classes.navlink}
            to={path}
            uppercase
            size="xx-small"
            fontFamily="barlow-italic-bold"
            color="--white-shadow"
            onClick={onClick}
          >
            {name}
          </NavLink>
        </div>
      </ListItemButton>
    </ListItem>
  );
};

interface MobileNavItemProps {
  item: NavigationItem;
}

const MobileNavItem: FC<MobileNavItemProps> = ({ item }) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const { name, path, subMenu } = item;

  const dispatch = useAppDispatch();

  const handleCloseFunc = () => {
    dispatch(GlobalStateActions.drawerFunc(false));
  };

  const handleDropdown = () => {
    setOpenDropdown((prev) => {
      return !prev;
    });
  };

  return (
    <>
      <ListItem className={classes['mobile__menu-item']}>
        <ListItemButton className={classes['mobile__menu-button']}>
          <div
            style={{
              flex: 1,
              textAlign: 'center',
              position: 'relative',
            }}
          >
            <NavLink
              className={classes.navlink}
              onClick={handleCloseFunc}
              to={path}
              uppercase
              size="x-small"
              fontFamily="barlow-italic-bold"
              color="--white-shadow"
            >
              {name}
            </NavLink>
            {subMenu && (
              <ListItemIcon className={clsx(classes['mobile__icon'])}>
                <ArrowDropDown
                  className={clsx(classes.arrow, {
                    [classes.active]: openDropdown,
                  })}
                  onClick={handleDropdown}
                />
              </ListItemIcon>
            )}
            <AnimatePresence>
              {subMenu && openDropdown && (
                <motion.div
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: 'auto', opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  transition={{ duration: 0.2 }}
                >
                  <Divider variant="middle" sx={{ marginTop: '12px' }} />
                  <List>
                    {subMenu.map((item) => {
                      return (
                        <MobileDropdownMenu
                          key={item.path}
                          path={`${path}${item.path}`}
                          onClick={handleCloseFunc}
                          name={item.name}
                        />
                      );
                    })}
                  </List>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </ListItemButton>
      </ListItem>
    </>
  );
};

export default memo(MobileNavItem);
