/* eslint-disable no-unused-vars */
import { Button } from '@mui/material';
import clsx from 'clsx';
import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';

import classes from './style.module.scss';
interface ImageUploadProps {
  id: string;
  valid?: boolean;
  center?: boolean;
  onInput: (id: string, file: File | null, isValid: boolean) => void;
  setPreviewUrlOutside?: React.Dispatch<
    React.SetStateAction<string | null | undefined>
  >;
  preview?: boolean;
}

export const ImageUpload: FC<ImageUploadProps> = ({
  id,
  center,
  onInput,
  setPreviewUrlOutside,
  preview,
  valid = false,
}) => {
  const [file, setFile] = useState<File | null>();
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [isValid, setIsValid] = useState(valid);

  const filePickerRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!file) {
      return;
    }
    const fileReader = new FileReader();
    fileReader.onload = () => {
      setPreviewUrl(fileReader.result as string);
    };
    fileReader.readAsDataURL(file);
  }, [file]);

  const pickImageHandler = () => {
    if (filePickerRef.current) {
      filePickerRef.current.click();
    }
  };

  const pickedHandler = (event: ChangeEvent<HTMLInputElement>) => {
    let pickedFile: File | null = null; // Deklaracja zmiennej poza blokiem if
    let fileIsValid = isValid || valid;

    if (event.target.files && event.target.files.length === 1) {
      pickedFile = event.target.files[0]; // Użyj tej samej zmiennej zamiast deklarować nową
      setFile(pickedFile);
      setIsValid(true);
      fileIsValid = true;
    } else {
      if (valid) {
        setIsValid(false);
        fileIsValid = false;
      } else {
        setIsValid(true);
        fileIsValid = true;
      }
    }
    if (valid) {
      onInput(id, pickedFile, valid);
    } else {
      onInput(id, pickedFile, fileIsValid);
    }
  };

  useEffect(() => {
    setPreviewUrlOutside?.(previewUrl);
  }, [previewUrl, setPreviewUrlOutside]);

  return (
    <div className={classes['form-control']}>
      <input
        id={id}
        ref={filePickerRef}
        type="file"
        style={{ display: 'none' }}
        accept=".jpg, .png, .jpeg"
        onChange={pickedHandler}
      />
      <div
        className={clsx(classes['image-upload'], { [classes.center]: center })}
      >
        {preview && (
          <div className={classes['image-upload__preview']}>
            {previewUrl && <img src={previewUrl} alt="Preview" />}
            {!previewUrl && <p>Please pick a image</p>}
          </div>
        )}
        <Button
          variant="gold-outlined"
          color="success"
          sx={{
            color: 'var(--main-gold)',
            marginTop: '16px',
          }}
          className={classes['pick-button']}
          type="button"
          onClick={pickImageHandler}
        >
          PICK IMAGE
        </Button>
      </div>
    </div>
  );
};
