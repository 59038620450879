import { IconButton } from '@components/UI';
import { CustomColors, SizesType } from '@components/UI/types';
import React, { FC } from 'react';

import classes from './socials.module.scss';

interface SocialsProps {
  color?: CustomColors;
  size?: SizesType;
}

export const Socials: FC<SocialsProps> = ({ color = 'inherit', size }) => {
  return (
    <div className={classes.socials}>
      <div className={classes.wrapper}>
        <IconButton
          variant="facebook"
          to="https://www.facebook.com/eventrulers"
          target="_blank"
          size={size}
          color={color}
          m={0.5}
          mr={2}
          aria-label="facebook link"
        />
        <IconButton
          variant="instagram"
          to="https://www.instagram.com/eventrulers"
          target="_blank"
          size={size}
          color={color}
          m={0.5}
          mr={2}
          aria-label="instagram link"
        />
        <IconButton
          variant="tik-tok"
          to="https://www.tiktok.com/@eventrulers"
          size={size}
          color={color}
          m={0.5}
          aria-label="tik-tok link"
        />
        <a
          href="https://www.weselezklasa.pl/ogloszenia-weselne/matyou-kwiatkowsky-prezenter-muzyczny-dj-wodzirej,39689/"
          target="_blank"
          rel="noreferrer"
        >
          <img
            style={{ height: '38px' }}
            src="/img/wesele-z-klasa.png"
            alt="Event Rulers Więcej niż Wodzireje"
          />
        </a>
      </div>
    </div>
  );
};
