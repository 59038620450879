import { fontSizeMap } from '@components/UI/styled';
import { CustomColors, FontFamilies, SizesType } from '@components/UI/types';
import { NavLink, NavLinkProps } from 'react-router-dom';
import styled from 'styled-components';

// Typ dla niestandardowych propsów rozszerzających NavLinkProps
export interface CustomNavLinkProps extends NavLinkProps {
  color?: CustomColors;
  uppercase?: boolean;
  size?: SizesType;
  fontFamily?: FontFamilies;
  isLogout?: boolean;
  hoverColor?: CustomColors;
}

// Stylizowany komponent NavLink przyjmujący niestandardowe propsy
export const StyledNavLinkRouter = styled(NavLink).attrs<CustomNavLinkProps>(
  (props) => {
    return {
      ...props,
    };
  }
)<CustomNavLinkProps>`
  color: ${({ color }) => {
    return color ? `var(--${color})` : 'inherit';
  }};
  text-transform: ${({ uppercase }) => {
    return uppercase ? 'uppercase' : 'none';
  }};
  font-size: ${({ size }) => {
    return fontSizeMap[size as SizesType]
      ? fontSizeMap[size as SizesType]
      : '1rem';
  }};
  font-family: ${({ fontFamily }) => {
    return fontFamily || 'inherit';
  }};
  padding: 1.2rem 1rem;

  &.active {
    color: ${({ color }) => {
      return color ? `var(--main-gold)` : 'inherit';
    }};
  }
  &.hover {
    color: ${({ color }) => {
      return color ? `var(--main-gold)` : 'inherit';
    }};
  }
`;
