import { useMobile } from '@hooks/useMobile';
import {
  Input as InputMUI,
  InputProps as InputPropsMUI,
  FormLabelProps,
  FormLabel,
} from '@mui/material';
import { TypographyProps } from '@mui/material/Typography/Typography';
import { ValidatorType } from '@utils/validators/artists-validators';
import clsx from 'clsx';
import React, { forwardRef, useReducer, useEffect } from 'react';

import { Typography, fontSizeMap } from '..';
import { AlignType, FontFamilies, SizesType } from '../types';

import classes from './input.module.scss';
import { InitialStateProps, inputReducer } from './reducers';

export interface InputProps extends InputPropsMUI {
  name: string;
  label: string;
  column?: boolean;
  fullWidth?: boolean;
  align?: AlignType;
  formLabel?: FormLabelProps;
  fontFamily?: FontFamilies;
  inputFontFamily?: FontFamilies;
  errorText: string;
  validators: ValidatorType[];
  value?: string;
  valid?: boolean;
  letterSpacing?: TypographyProps['letterSpacing'];
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      name,
      column,
      style,
      color,
      align = 'left',
      inputComponent = 'input',
      fullWidth,
      fontFamily,
      inputFontFamily,
      onInput,
      formLabel = {
        size: 'medium',
        color: '--black',
        width: 'inherit',
      },
      errorText = 'Error',
      validators,
      value,
      valid,
      letterSpacing,
      type,
      ...rest
    },
    ref
  ) => {
    const initialState: InitialStateProps = {
      val: value || '',
      isValid: valid || false,
      isTouched: false,
    };
    const [inputState, dispatch] = useReducer(inputReducer, initialState);
    const { val, isValid, isTouched } = inputState;
    const isMobile = useMobile();

    const error = !isValid && isTouched;

    const id = rest.id || `id-${name}`;

    useEffect(() => {
      if (onInput) {
        onInput(id, val, isValid);
      }
    }, [id, val, isTouched, isValid]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch({ type: 'CHANGE', val: event.target.value, validators });
    };

    const touchHandler = () => {
      dispatch({
        type: 'TOUCH',
      });
    };

    const compusedInputStyle = {
      ...style,
      color: `var(${color})`,
      fontFamily: inputFontFamily ? inputFontFamily : fontFamily,
      borderBottomColor: `var(${color})`,
      width: fullWidth ? '100%' : 'inherit',
    };

    const customInputClass = clsx(classes.wrapper, {
      [classes['component--full-width']]: fullWidth,
      [classes['column--full-width']]: fullWidth,
      [classes.column]: column,
      [classes.invalid]: error,
    });

    const inputClass = clsx(classes.input);

    return (
      <>
        <div className={customInputClass}>
          <FormLabel
            component="label"
            htmlFor={id}
            error={error}
            className={classes[align]}
            style={{
              fontSize: `${
                fontSizeMap[formLabel?.size as SizesType] || fontSizeMap.medium
              }`,
              color: `var(${formLabel?.color})`,
              width: fullWidth ? '100px' : 'inherit',
              letterSpacing: `${letterSpacing}px`,
              fontFamily,
            }}
          >
            {label}
          </FormLabel>
          <InputMUI
            ref={ref}
            type={type}
            inputComponent={inputComponent}
            className={inputClass}
            id={id}
            style={compusedInputStyle}
            value={value}
            onChange={handleChange}
            onBlur={touchHandler}
            error={error}
            inputProps={{
              className: `${classes.input}`,
            }}
            {...rest}
          />
        </div>

        <Typography
          component="p"
          size={isMobile ? 'xx-small' : 'x-small'}
          color="--cancel"
          mt={1}
          mb={2}
          ml={2}
        >
          {error ? errorText : ' '}
        </Typography>
      </>
    );
  }
);

export default Input;
