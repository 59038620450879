export const palette = {
  userColors: {
    '--basic-wheat': '#f5deb3',
    '--black': '#141417',
    '--background-color': '#000',
    '--background-color-soft': '#1a1818',
    '--background-color-soft-shadow': '#2826251b',
    '--white': '#fff',
    '--white-shadow': '#e7e3e5',
    '--pure-white-shadow': '#ffffff0d',
    ' --pure-white-shadow-strong': '#ffffffb3',
    '--disabled': '#ccc',
    '--disabled-shadow': '#c6c6c6',
    '--off-white': '#faf9f8',
    '--main-gold': '#d2b859',
    '--light-gold': '#ebc98c',
    '--dark-gold': '#957500',
    '--dark-contrast-gold': '#5b4905',
    '--heading-gold-dark': '#dda937bd',
    '--blue': '#70B7DC',
    '--light-blue': '#89CFF0',
    '--medium-blue': '#e8f2fc',
    '--soft-blue': '#f5f8ff',
    '--soft-dark-blue': '#588CA8',
    '--dark-blue': '#0e3a78',
    '--green': '#66CDAA',
    '--light-green': '#7FDAB8',
    '--medium-green': '#def2b1',
    '--soft-green': '#f7fcec',
    '--soft-dark-green': '#4D8B77',
    '--dark-green': '#447513',
    '--red': '#FF9999',
    '--light-red': '#FFAFAF',
    '--medium-red': '#f9f0f0',
    '--soft-red': '#f9f0f0',
    '--soft-dark-red': '#CC7F7F',
    '--dark-red': '#b82f1f',
    '--hot-casino-red': '#e6370d',
    '--orange': '#FFA07A',
    '--light-orange': '#FFAB7D',
    '--medium-orange': '#ffe0a9',
    '--soft-orange': '#fff4e3',
    '--soft-dark-orange': '#CC814F',
    '--dark-orange': '#d0690a',
    '--violet': '#B19CD9',
    '--light-violet': '#BEAEE2',
    '--medium-violet': '#e9d7ff',
    '--soft-violet': '#f9f6ff',
    '--soft-dark-violet': '#8E7CB2',
    '--dark-violet': '#5d1974',
    '--yellow': '#fefdbf',
    '--light-yellow': '#F9ECA2',
    '--medium-yellow': '#f8f6a9',
    '--soft-yellow': '#fffcea',
    '--soft-dark-yellow': '#C2B076',
    '--dark-yellow': '#aa6908',
    '--medium-grey': '#d3e4e4',
    '--soft-grey': '#e5ecec',
    '--dark-grey': '#445757',
    '--beige': '#F5F5DC',
    '--medium-beige': '#d2cfcb',
    '--soft-beige': '#e9e7e4',
    '--dark-beige': '#676460',
    '--teal': '#008080',
    '--medium-teal': '#d7fbfc',
    '--soft-teal': '#f8ffff',
    '--dark-teal': '#003742',
    '--wheat': '#f5deb3',
    '--wheat-shadow': '#CBB386',
    '--wheat-shadow-light': '#8B754C',
    '--wheat-shadow-dark': '#2E281D',
    '--background-gold': '#ffcf78',
    '--color-gold': '#423401',
    '--hover-color-gold': '#5e4e13',
    '--border-gold': '#b8a251',
    '--hover-gold': '#edb853',
    '--background-submit': '#ffcf78',
    '--color-submit': '#423401',
    '--border-submit': '#b8a251',
    '--hover-submit': '#edb853',
    '--shadow-grey': '#ffffff99',
    '--background-cancel': '#eb0100',
    '--color-cancel': '#ffdede',
    '--hover-color-cancel': '#fff',
    '--hover-cancel': '#dd0000',
    '--peach': '#ffd37a',
    '--info-1': '#0e3a78',
    '--info-2': '#4e86cf',
    '--positive-1': '#1d8560',
    '--positive-2': '#49b989',
    '--positive-3': '#d3f2e4',
    '--warning-1': '#ffbe61',
    '--warning-2': '#ffdd84',
    '--success': '#4caf50',
    '--success-shadow': '#ccff90',
    '--cancel': '#eb0100',
    '--cancel-shadow': '#ccff90',
    '--warning': '#ff6700',
    '--warning-shadow': '#fff',
    '--negative-1': '#ce4343',
    '--negative-2': '#f76262',
    inherit: '',
  },
  primary: {
    main: '#282625',
    transparentDark: '#28262533',
  },
  gold: {
    main: '#d2b859',
    light: '#ebc98c',
    dark: '#957500',
    dark_contrast: '#5b4905',
    transparentDark: '#dda937bd',
  },
  goldButton: {
    background: '#ffcf78',
    color: '#333333',
    border: '#dc9014',
    hover: '#edb853',
  },
  blue: {
    main: '#70B7DC',
    light: '#89CFF0',
    medium: '#e8f2fc',
    soft: '#f5f8ff',
    darkSoft: '#588CA8',
    dark: '#0e3a78',
  },
  green: {
    main: '#66CDAA',
    light: '#7FDAB8',
    medium: '#def2b1',
    soft: '#f7fcec',
    darkSoft: '#4D8B77',
    dark: '#447513',
  },
  red: {
    main: '#FF9999',
    light: '#FFAFAF',
    medium: '#ffdad2',
    soft: '#f9f0f0',
    darkSoft: '#CC7F7F',
    dark: '#b82f1f',
  },
  orange: {
    main: '#FFA07A',
    light: '#FFAB7D',
    medium: '#ffe0a9',
    soft: '#fff4e3',
    darkSoft: '#CC814F',
    dark: '#d0690a',
  },
  violet: {
    main: '#B19CD9',
    light: '#BEAEE2',
    medium: '#e9d7ff',
    soft: '#f9f6ff',
    darkSoft: '#8E7CB2',
    dark: '#5d1974',
  },
  yellow: {
    main: '#fefdbf',
    light: '#F9ECA2',
    medium: '#f8f6a9',
    soft: '#fffcea',
    darkSoft: '#C2B076',
    dark: '#aa6908',
  },
  beige: {
    main: '#F5F5DC',
    medium: '#d2cfcb',
    soft: '#e9e7e4',
    dark: '#676460',
  },
  teal: {
    main: '#008080',
    medium: '#d7fbfc',
    soft: '#f8ffff',
    dark: '#003742',
  },
  pureWhite: {
    main: '#ffffff',
    softShadow: '#ffffff33',
    shadow: '#ffffffcc',
  },
  backgroundColor: {
    main: '#000',
    light: '#282625',
    transparentDark: '#000000cc',
  },
};
