import { Divider, MotionButton, Typography } from '@components/UI';
import { useMobile } from '@hooks/index';
import useScrollToSection from '@hooks/useScrollSection';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import clsx from 'clsx';

import classes from './style.module.scss';

export const SummarySection = () => {
  const isMobile = useMobile();
  const summaryText = `Planujesz wydarzenie, które ma na zawsze pozostać w pamięci uczestników? Chcesz, aby każdy element idealnie oddawał charakter okazji? Niezależnie od tego, czy marzysz o eleganckim przyjęciu, dynamicznym evencie czy ekskluzywnym wieczorze tematycznym, jesteśmy tutaj, aby pomóc Ci zrealizować Twoje wizje. Skontaktuj się z nami, aby omówić Twoje oczekiwania i potrzeby. Wspólnie stworzymy niezapomniane chwile, celebrując to, co dla Ciebie najważniejsze. Nie czekaj, zacznijmy planować Twoje idealne wydarzenie już dziś!`;

  const scrollToContactForm = useScrollToSection(
    'contact-form-section',
    isMobile ? -77 : -83
  );

  const containerVariants = {
    hidden: {
      marginBottom: 100,
      y: isMobile ? 50 : 10,
      scale: 1,
      opacity: 0,
    },
    visable: {
      marginBottom: 10,
      y: isMobile ? 50 : 100,
      opacity: 1,
      scale: isMobile ? 2 : 4,
      transition: {
        delay: 2,
        repeat: Infinity,
        repeatType: 'yoyo' as 'loop' | 'mirror' | 'reverse',
        duration: 1.5,
      },
    },
  };

  return (
    <div className={classes.summary}>
      <Divider variant="middle" />
      <div className={classes.heading}>
        <Typography
          component="h4"
          size={{
            xs: 'small',
            sm: 'medium',
            md: 'large',
            lg: 'x-large',
            xl: 'x-large',
          }}
          color="--white-shadow"
          fontFamily="barlow-italic"
          letterSpacing={1}
          textAlign="center"
          my={4}
          mx={isMobile ? 1 : 0}
        >
          Zróbmy Razem Coś Wyjątkowego - Skontaktuj się z Nami!
        </Typography>
        <Typography
          component="pre"
          size={{
            xs: 'small',
            md: 'small',
            lg: 'medium',
            xl: 'medium',
          }}
          color="--basic-wheat"
          fontFamily="barlow-italic"
          textAlign="justify"
          mx={isMobile ? 2 : 0}
        >
          {summaryText}
        </Typography>
        <div className={classes['arrow-container']}>
          <MotionButton
            variants={containerVariants}
            className={clsx(classes.arrow, {
              [classes['arrow--mobile']]: isMobile,
            })}
            ariaLabel="Wciśnij aby przejść w dół"
          >
            <KeyboardDoubleArrowDownIcon
              style={{ color: 'var(--light-gold)' }}
              onClick={scrollToContactForm}
            />
          </MotionButton>
        </div>
      </div>
    </div>
  );
};
