/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  PrevButton,
  NextButton,
} from '@components/UI/Button/controll-buttons/ControlsButton';
import { Carousel } from '@components/UI/Carousel';
import { useSwiperRef } from '@components/UI/Swiper';
import { CustomColors, FontFamilies, SizesType } from '@components/UI/types';
import { TypographyStyleProps } from '@components/UI/Typography/Typography.styles';
import { BoxProps } from '@mui/material';
import { SlidesProps } from '@pages/home/offer/offerContent';
import clsx from 'clsx';
import React, { FC } from 'react';

import { useNavigate } from 'react-router-dom';

import { LazyImage, LazyImageProps, Typography } from '..';

import classes from './style.module.scss';

interface CarouserlHeaderProps {
  showButton?: boolean;
  slidesPerView?: number;
  spaceBetween?: number;
  items: SlidesProps[];
  cardProps?: Omit<OfferCardProps, 'src' | 'title' | 'subTitle'>;
  className?: BoxProps['className'];
  grabCursor?: boolean;
  loop?: boolean;
}

export const CarouselHeader: FC<CarouserlHeaderProps> = ({
  showButton = false,
  grabCursor = false,
  loop = false,
  slidesPerView = 2,
  spaceBetween = 24,
  items,
  cardProps,
  className,
}) => {
  const [nextEl, nextElRef] = useSwiperRef();
  const [prevEl, prevElRef] = useSwiperRef();
  return (
    <div className={clsx(classes.wrapper, className)}>
      {showButton && <PrevButton elementRef={prevElRef} color="--dark-grey" />}
      <Carousel
        className={classes.carousel}
        slidesPerView={slidesPerView}
        spaceBetween={spaceBetween}
        navigation={{
          // @ts-ignore
          prevEl,
          // @ts-ignore
          nextEl,
        }}
        autoplay={{ delay: 4000, disableOnInteraction: false }}
        loop={loop}
        grabCursor={grabCursor}
        pagination={{ clickable: false, bulletActiveClass: classes.bullet }}
        slides={items.map((item) => {
          return (
            <OfferCard
              key={item.name}
              subTitle={item.subTitle || ''}
              title={item.name}
              src={item.img}
              height={cardProps?.height}
              to={item.path}
            />
          );
        })}
      />
      {showButton && <NextButton elementRef={nextElRef} color="--dark-grey" />}
    </div>
  );
};

interface OfferCardProps {
  title: string;
  subTitle: string;
  src: LazyImageProps['src'];
  alt?: LazyImageProps['alt'];
  to?: string;
  height?: LazyImageProps['height'];
  color?: CustomColors;
  titleTextAlign?: TypographyStyleProps['textAlign'];
  titleSize?: SizesType;
  subtitleTextAlign?: TypographyStyleProps['textAlign'];
  subtitleSize?: SizesType;
  titleFontFamily?: FontFamilies;
  subtitleFontFamily?: FontFamilies;
}

const OfferCard: FC<OfferCardProps> = ({
  title,
  subTitle,
  src,
  alt,
  to,
  height,
  titleTextAlign,
  subtitleTextAlign = 'center',
  color = '--dark-grey',
  titleSize = 'x-small',
  subtitleSize = 'small',
  titleFontFamily = 'barlow-italic-bold',
  subtitleFontFamily = 'barlow-italic',
}) => {
  const navigate = useNavigate();
  return (
    <div
      key={title}
      className={classes.item}
      onClick={() => {
        return navigate(to as string);
      }}
    >
      <LazyImage
        src={src}
        width="100%"
        height={height}
        alt={alt ? alt : `Zdjęcie przedstawiające usługę weselną ${title}`}
      />
      <div className={classes.text}>
        <Typography
          sx={{ border: '2px solid green' }}
          uppercase
          component="h3"
          fontFamily={titleFontFamily}
          size={titleSize}
          color={color}
          letterSpacing={4}
          textAlign={titleTextAlign}
        >
          {title}
        </Typography>
        <Typography
          component="p"
          fontFamily={subtitleFontFamily}
          color={color}
          size={subtitleSize}
          letterSpacing={4}
          textAlign={subtitleTextAlign}
        >
          {subTitle}
        </Typography>
      </div>
    </div>
  );
};
