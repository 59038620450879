import {
  Box,
  DecoratorSVG,
  DecoratorVariant,
  Divider,
  HotCasinoDurationLogo,
  Typography,
} from '@components/UI';
import { useMobile } from '@hooks/useMobile';
import { BoxProps } from '@mui/material';
import clsx from 'clsx';
import React, { FC, ReactNode } from 'react';

import classes from './style.module.scss';

interface PageContentProps {
  subTitle?: string;
  children?: ReactNode;
  maxWidth?: BoxProps['maxWidth'];
  className?: BoxProps['className'];
  decorator?: DecoratorVariant;
}

export const PageContent: FC<PageContentProps> = ({
  subTitle,
  children,
  maxWidth = 1200,
  className,
  decorator,
}) => {
  const isMobile = useMobile();
  return (
    <Box
      className={classes.content}
      maxWidth={maxWidth}
      property="hasOfferCatalog"
      typeof="OfferCatalog"
    >
      <div className={classes.ornament}>
        {decorator === 'hot_casino' ? (
          <HotCasinoDurationLogo
            color="--main-gold"
            aria-label="logo-casino"
            duration={1}
            delay={0.3}
            height={isMobile ? 100 : 150}
          />
        ) : (
          <DecoratorSVG
            variant={decorator}
            height={isMobile ? 20 : 50}
            color="--main-gold"
            duration={2}
            delay={0.5}
            m={isMobile ? 0 : 2}
          />
        )}
      </div>
      <Typography
        fontFamily="great-vibes"
        component="h2"
        letterSpacing={3}
        textAlign="center"
        size={{
          xs: 'large',
          sm: 'x-large',
          md: 'x-large',
          lg: 'xx-large',
          xl: 'xx-large',
        }}
        mb={isMobile ? 4 : 6}
        mx={isMobile ? 2 : 0}
        color="--main-gold"
      >
        {subTitle}
      </Typography>
      <div className={classes.divider}>
        <Divider variant="middle" />
      </div>
      <div className={clsx(classes.children, className)}>{children}</div>
    </Box>
  );
};
