import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

// import { websocketMiddleware } from './middleware/webSocketMiddleware';
import rootReducer from './rootReducer';

const store = configureStore({
  reducer: rootReducer,
  // middleware: (getDefaultMiddleware) => {
  //   return getDefaultMiddleware().concat(websocketMiddleware);
  // },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export default store;
