/* eslint-disable @typescript-eslint/no-explicit-any */
import StarOutlineRoundedIcon from '@mui/icons-material/StarOutlineRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import {
  RatingProps as RatingPropsMUI,
  FormHelperText,
  Rating as RatingMUI,
} from '@mui/material';
import React from 'react';

import { Box, BoxCustomProps, Typography } from '..';

import './Rating.styles.scss';
import { CustomColors } from '../types';

import SvgRatingArrow from './RatingArrow';

type Size = 'small' | 'medium' | 'large';

export interface RatingProps extends RatingPropsMUI {
  variant?: 'triangles' | 'stars' | 'stars-outline';
  size?: Size;
  color?: CustomColors;
  iconStyle?: React.CSSProperties;
  label?: string;
  error?: string;
  hideError?: boolean;
  containerProps?: BoxCustomProps;
  FormHelperTextProps?: any;
}

const RatingArrow = (
  <SvgRatingArrow
    viewBox="0 0 36 67"
    style={{ width: '36px', height: '67px' }}
  />
);

export const Rating = ({
  variant = 'stars',
  size = 'medium',
  color = '--main-gold',
  iconStyle = {},
  label,
  error,
  value,
  hideError,
  containerProps,
  FormHelperTextProps,
  ...props
}: RatingProps) => {
  const [hoverValue, setHoverValue] = React.useState(-1);
  const { getLabelText } = props;
  const { Icon, EmptyIcon } = getIconType(variant);
  const style = {
    ...getStyle(size),
    ...iconStyle,
  };
  const _value = hoverValue !== -1 ? hoverValue : Number(value);
  const _label = (getLabelText && getLabelText(_value)) || label;

  return (
    <div>
      {error && !hideError && (
        <FormHelperText {...FormHelperTextProps} error>
          {error}
        </FormHelperText>
      )}
      <Box {...containerProps}>
        {!!_label && (
          <Typography component="p" mb={2}>
            {_label}
          </Typography>
        )}
        <RatingMUI
          {...props}
          value={_value}
          onChangeActive={(_event, newHover) => {
            return setHoverValue(newHover);
          }}
          classes={{
            icon: 'rating__icon',
            iconEmpty: 'rating__icon-empty',
            iconHover: 'rating__icon-hover',
          }}
          style={{ color: `var(${color})` }}
          icon={Icon ? <Icon style={style} /> : undefined}
          emptyIcon={
            EmptyIcon ? (
              <EmptyIcon
                style={style}
                stroke={error ? 'var(--negative-1)' : undefined}
              />
            ) : undefined
          }
        />
      </Box>
    </div>
  );
};

const getStyle = (size: RatingProps['size']) => {
  switch (size) {
    case 'large': {
      return { width: '38px', height: '76px' };
    }
    case 'medium': {
      return { width: '30px', height: '60px' };
    }
    case 'small': {
      return { width: '5vw', maxWidth: '24px', height: '53px' };
    }
  }
};

const getIconType: any = (variant: RatingProps['variant']) => {
  switch (variant) {
    case 'triangles':
      return {
        Icon: () => {
          return RatingArrow;
        },
        EmptyIcon: () => {
          return RatingArrow;
        },
      };
    case 'stars':
      return {
        Icon: StarRoundedIcon,
        EmptyIcon: StarRoundedIcon,
      };
    case 'stars-outline': {
      return {
        Icon: StarRoundedIcon,
        EmptyIcon: StarOutlineRoundedIcon,
      };
    }
    default:
      return {
        Icon: null,
        EmptyIcon: null,
      };
  }
};
