import { Divider, LazyImage, LazyImageProps } from '@components/UI';
import { FontFamilies } from '@components/UI/types';
import React, { FC } from 'react';

import classes from './style.module.scss';

interface PageImageProps {
  src: LazyImageProps['src'];
  objectPosition?: LazyImageProps['objectPosition'];
  pageTitle?: string;
  fontFamily?: FontFamilies;
}

export const PageImage: FC<PageImageProps> = ({
  src,
  pageTitle,
  objectPosition,
}) => {
  return (
    <div className={classes.background}>
      <div
        className={classes.img}
        aria-label={`wrapper zdjęcia w tle strony ${pageTitle}`}
      >
        <LazyImage
          role="img"
          className={classes.lazy}
          src={src}
          objectPosition={objectPosition}
          loading="eager"
          backgroundAttachment="fixed"
          alt={`zdjęcie w tle strony ${pageTitle}`}
        />
        <h1 className={classes.text}>{pageTitle}</h1>
      </div>

      <Divider variant="fullWidth" color="--basic-wheat" />
    </div>
  );
};
