import { useEffect, useRef, useState } from 'react';
import SwiperCore, {
  Autoplay,
  Mousewheel,
  Navigation,
  Pagination,
  SwiperOptions,
  Swiper as SwiperTypes,
} from 'swiper';
import { Swiper, SwiperSlide, useSwiper, useSwiperSlide } from 'swiper/react';

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Autoplay, Mousewheel]);

// https://github.com/nolimits4web/swiper/issues/3855#issuecomment-829278905
const useSwiperRef = () => {
  const [wrapper, setWrapper] = useState(null);
  const ref = useRef(null);

  useEffect(() => {
    setWrapper(ref.current);
  }, []);

  return [wrapper, ref];
};

export {
  Swiper,
  SwiperSlide,
  useSwiperRef,
  SwiperTypes,
  useSwiperSlide,
  useSwiper,
};
export type { SwiperOptions };
