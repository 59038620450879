import { CustomColors } from '@components/UI/types';
import { SvgIcon, SvgIconProps } from '@mui/material';
import React, { CSSProperties, FC, ReactNode } from 'react';

export interface CustomSvgIconProps extends Omit<SvgIconProps, 'color'> {
  hoverColor?: CustomColors;
  color?: CustomColors;
  children?: ReactNode;
  viewBox?: string;
  height?: CSSProperties['height'];
  width?: CSSProperties['width'];
  className?: string;
}

export const CustomSvgIcon: FC<CustomSvgIconProps> = ({
  children,
  className,
  viewBox = '0 0 512 468.61',
  color = '--black',
  height = 'auto',
  width = 24,
  ...props
}) => {
  return (
    <SvgIcon
      viewBox={viewBox}
      className={className}
      style={{ color: `var(${color})`, height, width }}
      {...props}
    >
      {children}
    </SvgIcon>
  );
};
