/* eslint-disable no-unused-vars */
enum ValidatorEnums {
  VALIDATOR_TYPE_REQUIRE = 'REQUIRE',
  VALIDATOR_TYPE_MINLENGTH = 'MINLENGTH',
  VALIDATOR_TYPE_MAXLENGTH = 'MAXLENGTH',
  VALIDATOR_TYPE_MIN = 'MIN',
  VALIDATOR_TYPE_MAX = 'MAX',
  VALIDATOR_TYPE_EMAIL = 'EMAIL',
  VALIDATOR_TYPE_FILE = 'FILE',
  VALIDATOR_TYPE_PHONE = 'PHONE',
}

export type ValidatorType =
  | { type: ValidatorEnums.VALIDATOR_TYPE_REQUIRE }
  | { type: ValidatorEnums.VALIDATOR_TYPE_FILE }
  | { type: ValidatorEnums.VALIDATOR_TYPE_PHONE }
  | {
      type:
        | ValidatorEnums.VALIDATOR_TYPE_MINLENGTH
        | ValidatorEnums.VALIDATOR_TYPE_MAXLENGTH
        | ValidatorEnums.VALIDATOR_TYPE_MIN
        | ValidatorEnums.VALIDATOR_TYPE_MAX;
      value: number;
    }
  | { type: ValidatorEnums.VALIDATOR_TYPE_EMAIL };

export const VALIDATOR_REQUIRE = (): ValidatorType => {
  return { type: ValidatorEnums.VALIDATOR_TYPE_REQUIRE };
};
export const VALIDATOR_FILE = (): ValidatorType => {
  return { type: ValidatorEnums.VALIDATOR_TYPE_FILE };
};
export const VALIDATOR_MINLENGTH = (value: number): ValidatorType => {
  return {
    type: ValidatorEnums.VALIDATOR_TYPE_MINLENGTH,
    value,
  };
};
export const VALIDATOR_MAXLENGTH = (value: number): ValidatorType => {
  return {
    type: ValidatorEnums.VALIDATOR_TYPE_MAXLENGTH,
    value,
  };
};
export const VALIDATOR_MIN = (value: number): ValidatorType => {
  return { type: ValidatorEnums.VALIDATOR_TYPE_MIN, value };
};
export const VALIDATOR_MAX = (value: number): ValidatorType => {
  return { type: ValidatorEnums.VALIDATOR_TYPE_MAX, value };
};
export const VALIDATOR_EMAIL = (): ValidatorType => {
  return { type: ValidatorEnums.VALIDATOR_TYPE_EMAIL };
};

export const VALIDATOR_PHONE = (): ValidatorType => {
  return { type: ValidatorEnums.VALIDATOR_TYPE_PHONE };
};

export const validate = (value: string, validators: ValidatorType[]) => {
  let isValid = true;
  for (const validator of validators) {
    switch (validator.type) {
      case ValidatorEnums.VALIDATOR_TYPE_REQUIRE:
        isValid = isValid && value.trim().length > 0;
        break;
      case ValidatorEnums.VALIDATOR_TYPE_MINLENGTH:
        isValid = isValid && value.trim().length >= validator.value;
        break;
      case ValidatorEnums.VALIDATOR_TYPE_MAXLENGTH:
        isValid = isValid && value.trim().length <= validator.value;
        break;
      case ValidatorEnums.VALIDATOR_TYPE_MIN:
        isValid = isValid && +value >= validator.value;
        break;
      case ValidatorEnums.VALIDATOR_TYPE_MAX:
        isValid = isValid && +value <= validator.value;
        break;
      case ValidatorEnums.VALIDATOR_TYPE_PHONE:
        {
          const phonePattern = /^[0-9\-+]{9,15}$/;
          isValid = isValid && phonePattern.test(value);
        }
        break;
      case ValidatorEnums.VALIDATOR_TYPE_EMAIL:
        isValid = isValid && /^\S+@\S+\.\S+$/.test(value);
        break;
    }
  }
  return isValid;
};
