import { combineReducers } from '@reduxjs/toolkit';

import globalStateSlice from './slices/globalStateReducer';
import schemaReducer from './slices/schemaReducer';
import snackbarReducer from './slices/snackbarReducer';
import userReducer from './slices/userReducer';

const rootReducer = combineReducers({
  globalState: globalStateSlice,
  snackbarState: snackbarReducer,
  user: userReducer,
  schema: schemaReducer,
});

export default rootReducer;
