import { useState, useEffect } from 'react';

export const useLandscape = () => {
  const [orientation, setOrientation] = useState(
    window.innerWidth > window.innerHeight ? true : false
  );

  const checkOrientation = () => {
    setOrientation(window.innerWidth > window.innerHeight ? true : false);
  };

  useEffect(() => {
    checkOrientation();
    window.addEventListener('resize', checkOrientation);

    return () => {
      window.removeEventListener('resize', checkOrientation);
    };
  }, []);

  return orientation;
};
