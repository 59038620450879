import Button, { ButtonProps } from '@mui/material/Button/Button';
import { ReactNode } from 'react';
import styled from 'styled-components';

export interface ButtonStyleProps extends ButtonProps {
  fullWidth?: boolean;
  endIcon?: ReactNode; // Dodano niestandardowy typ koloru
}

export const ButtonStyled = styled(Button)<ButtonStyleProps>(({
  fullWidth,
  variant,
  style,
}) => {
  let compusedStyles = { ...style };

  switch (variant) {
    case 'transparent':
      compusedStyles = { ...style, background: 'none' };
      break;
    case 'outlined':
      compusedStyles = {
        ...style,
        border: `2.5px solid var(--main-gold) !important`,
      };
      break;
    default:
      compusedStyles;
      break;
  }

  return {
    width: fullWidth ? '100%' : 'auto',
    ...compusedStyles,
  };
});
