import { Box as BoxMUI, BoxProps, styled } from '@mui/material';
import React, { FC } from 'react';

import { CustomColors } from '../types';

export interface BoxCustomProps extends Omit<BoxProps, 'color' | 'bgColor'> {
  color?: CustomColors;
  bgColor?: CustomColors;
}

export const Box: FC<BoxCustomProps> = ({ children, ...props }) => {
  return <BoxStyled {...props}>{children}</BoxStyled>;
};

const BoxStyled = styled(BoxMUI)<BoxCustomProps>(({ color, bgColor }) => {
  return {
    color: `var(${color})`,
    backgroundColor: `var(${bgColor})`,
  };
});
