import { ButtonBase } from '@mui/material';
import { theme } from '@theme/theme';
import clsx from 'clsx';
import { forwardRef, useMemo } from 'react';

import { fontSizeMap } from '../styled';

import { SizesType } from '../types';

import classes from './button.module.scss';
import { ButtonStyled, ButtonStyleProps } from './Button.styles';
import { ButtonCoreProps, CustomButtonProps } from './Button.types';

type CompusedStyle = {
  borderColor: string;
  color: string;
  background: string;
  fontSize: string;
  border?: string;
  padding?: string;
};

export const ButtonCore = forwardRef<HTMLButtonElement, ButtonCoreProps>(
  ({ onClick, RouterLink, disabled, children, ...props }, ref) => {
    const component = props.href || props.to ? RouterLink : 'button';
    const style = { ...props.style };
    return (
      <ButtonBase
        ref={ref}
        {...props}
        style={style}
        focusRipple
        onClick={onClick}
        component={component}
        disabled={disabled}
      >
        {children}
      </ButtonBase>
    );
  }
);

export const CustomButton = forwardRef<HTMLButtonElement, CustomButtonProps>(
  (
    {
      children,
      onClick,
      fullWidth = false,
      border,
      disabled,
      target,
      variant,
      className,
      ...props
    },
    ref
  ) => {
    const compusedProps = target &&
      target === '_blank' && {
        target: '_blank',
        rel: 'noopener noreferrer',
      };

    return (
      <ButtonCore
        ref={ref}
        {...props}
        {...compusedProps}
        onClick={onClick}
        disabled={disabled}
        border={border}
        className={clsx(classes.button, className, {
          [classes['full-width']]: fullWidth,
          [classes['disabled-on']]: disabled,
          [classes[`${variant}`]]: variant,
          [classes.border]: border,
        })}
      >
        {children}
      </ButtonCore>
    );
  }
);

export const Button = forwardRef<HTMLButtonElement, ButtonStyleProps>(
  (
    {
      children,
      className,
      fullWidth,
      color,
      size,
      variant,
      onClick,
      endIcon,
      disabled,
      ...props
    },
    ref
  ) => {
    const compusedStyle = useMemo(() => {
      const style: CompusedStyle = {
        borderColor: `${
          theme.palette.userColors[color as string] || 'defaultColor'
        }`,
        color: `${theme.palette.userColors[color as string] || 'defaultColor'}`,
        background: 'none',
        fontSize: fontSizeMap[size as SizesType] || 'defaultSize',
      };

      if (variant === 'outlined') {
        style.border = `2.5px solid ${
          theme.palette.userColors[color as string] || 'defaultColor'
        }`;
      }

      const paddingMap: Record<string, string> = {
        // Twoje mapowanie paddingu
      };

      const safeSize = size || 'defaultSize';
      style.padding = paddingMap[safeSize] || '4px 20px';

      if (disabled) {
        style.color = 'var(--disabled)';
        style.borderColor = 'var(--disabled) !important';
      }

      return style;
    }, [color, size, variant, disabled]);

    return (
      <ButtonStyled
        ref={ref}
        // disabled={disabled}
        endIcon={endIcon as React.ReactNode}
        className={clsx(classes.button, className, {
          [classes['full-width']]: fullWidth,
          [classes[`${variant}`]]: variant,
        })}
        sx={{
          ...compusedStyle,
        }}
        onClick={onClick as React.MouseEventHandler<HTMLButtonElement>}
        {...props}
      >
        {children}
        {endIcon}
      </ButtonStyled>
    );
  }
);
