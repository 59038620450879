import { useMemo } from 'react';
import { BreadcrumbListProps, BreadcrumbProps } from 'src/types/schemaOrg';

const useBreadcrumbs = (url: string): BreadcrumbListProps => {
  return useMemo(() => {
    const parts = url.split('/').filter((part) => {
      return part.trim() !== '' && part !== 'https:';
    });
    const baseSiteUrl = 'https://eventrulers.pl';
    const breadcrumbList: BreadcrumbProps[] = [
      {
        '@type': 'ListItem',
        position: 1,
        name: 'Strona główna',
        item: baseSiteUrl,
      },
    ];

    let currentPath = baseSiteUrl;

    parts.forEach((part, index) => {
      currentPath += `/${part}`;
      breadcrumbList.push({
        '@type': 'ListItem',
        position: index + 2, // +2 because position 1 is always "Strona główna"
        name: part.replace(/-/g, ' ').replace(/\?.*$/, '') || 'Podstrona', // Remove GET parameters and replace hyphens
        item: currentPath,
      });
    });

    return {
      '@type': 'BreadcrumbList',
      '@id': `${currentPath}/#breadcrumb`,
      itemListElement: breadcrumbList,
    };
  }, [url]);
};

export default useBreadcrumbs;
