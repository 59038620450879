import { Box } from '@mui/material';
import React from 'react';

import { StyledDivider } from './Divider.styles';
import { DividerProps, VerticalSeparatorProps } from './Divider.types';

export const Divider = ({
  color = '--main-gold',
  inverted,
  component = 'hr',
  ...props
}: DividerProps) => {
  return (
    <StyledDivider
      inverted={inverted}
      component={component}
      sx={{
        borderColor: `var(${color})`,
      }}
      {...props}
    />
  );
};

export const VerticalSeparator = ({
  height = 32,
  color = '--main-gold',
  style,
  ...props
}: VerticalSeparatorProps) => {
  return (
    <Box
      component="span"
      display="inline-block"
      ml={2}
      mr={2}
      style={{
        height,
        ...style,
      }}
      {...props}
    >
      <Divider orientation="vertical" color={color} />
    </Box>
  );
};
