import { useTheme } from '@mui/material/styles';
import useMediaQueryMUI from '@mui/material/useMediaQuery';

type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export const useMediaQuery = (breakpoint: Breakpoint): boolean => {
  const theme = useTheme();

  return useMediaQueryMUI(theme.breakpoints.down(breakpoint));
};
