import { Drawer as DrawerMUI, DrawerProps } from '@mui/material';
import React, { FC } from 'react';

import { CustomColors } from '../types';

interface CustomDrawerProps extends DrawerProps {
  unmountOnExit?: boolean;
  bgColor?: CustomColors;
}

export const Drawer: FC<CustomDrawerProps> = ({
  anchor = 'top',
  unmountOnExit = true,
  bgColor = '--background-color-soft',
  children,
  ...rest
}) => {
  return (
    <DrawerMUI
      {...rest}
      anchor={anchor}
      sx={{
        '& .MuiDrawer-paper': {
          backgroundColor: `var(${bgColor})`,
        },
      }}
      SlideProps={{ unmountOnExit }}
    >
      {children}
    </DrawerMUI>
  );
};
