import { useForm } from '@hooks/form-hook';
import { useMobile } from '@hooks/useMobile';
import { Tooltip } from '@mui/material';
import { InputState } from '@pages/about';
import { useHttpClient } from '@pages/auth/hooks/useHttpClient';
import { useAppDispatch } from '@store/hooks';
import { snackbarAction } from '@store/slices/snackbarReducer';
import { useMediaQuery } from '@theme/hooks/useMediaQuery';
import {
  VALIDATOR_EMAIL,
  VALIDATOR_MAXLENGTH,
  VALIDATOR_MINLENGTH,
  VALIDATOR_PHONE,
  VALIDATOR_REQUIRE,
} from '@utils/validators/artists-validators';
import { AxiosError } from 'axios';
import React, { FormEvent, useRef, useState } from 'react';
import Confetti from 'react-confetti';

import { useLocation } from 'react-router-dom';
import {
  ContactFormProps,
  ContactMessageProps,
  InputPolicyState,
  InputTypeState,
} from 'src/types/contact-form';

import {
  Box,
  Button,
  Checkbox,
  CheckboxHandles,
  DatePicker,
  DatePickerHandles,
  Input,
  LinkComponent,
  Loader,
  MultipleCheckbox,
  SectionLayout,
  SnackbarProps,
  Textarea,
  Typography,
} from '..';

import classes from './style.module.scss';

const initialState: ContactFormProps['inputs'] = {
  contact_name: {
    value: '',
    isValid: false,
  },
  contact_email: {
    value: '',
    isValid: false,
  },
  contact_phone: {
    value: '',
    isValid: false,
  },
  contact_place: {
    value: '',
    isValid: false,
  },
  contact_date: {
    value: '',
    isValid: false,
  },
  contact_type: {
    value: null,
    isValid: false,
  },
  contact_private_policy: {
    value: false,
    isValid: false,
  },
  additional_information: {
    value: '',
    isValid: true,
  },
};

export const ContactForm = () => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [confetti, setConfetti] = useState(false);

  const location = useLocation();

  const isMobile = useMobile();

  const url = `${process.env.REACT_APP_SERVER_URL}/server/api/contact`;

  const sm = useMediaQuery('sm');
  const md = useMediaQuery('md');

  const datePickerRef = useRef<DatePickerHandles>(null);
  const checkboxRef = useRef<CheckboxHandles>(null);
  const multiCheckboxRef = useRef<CheckboxHandles>(null);

  const { sendRequest, isLoading } = useHttpClient();
  const dispatch = useAppDispatch();

  const openTooltipHandler = () => {
    setTooltipOpen(true);
  };

  const closeTooltipHandler = () => {
    setTooltipOpen(false);
  };

  const { inputHandler, formState, setFormData } = useForm<{
    contact_name: InputState;
    contact_email: InputState;
    contact_phone: InputState;
    contact_place: InputState;
    contact_date: InputState;
    contact_type: InputTypeState;
    contact_private_policy: InputPolicyState;
    additional_information: InputState;
  }>(initialState, false);

  const handleSelectionChange = (
    selected: Array<string | number> | string | number | null
  ) => {
    inputHandler('contact_type', selected as string, !!selected);
  };

  const handleDate = (date: string) => {
    if (date !== '' && date !== 'Invalid Date') {
      inputHandler('contact_date', date, true);
    } else {
      inputHandler('contact_date', date, false);
    }
  };

  const handleChecked = (isChecked: boolean) => {
    inputHandler('contact_private_policy', isChecked, isChecked);
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (!formState.isValid) {
      return openTooltipHandler();
    }

    try {
      const {
        contact_date,
        contact_name,
        contact_email,
        contact_phone,
        contact_place,
        contact_type,
        additional_information,
      } = formState.inputs;

      const body = {
        contact_name: contact_name.value,
        contact_date: contact_date.value,
        contact_email: contact_email.value,
        contact_phone: contact_phone.value,
        contact_place: contact_place.value,
        contact_type: contact_type.value,
        additional_information: additional_information.value,
        location_path: location.pathname,
      } as unknown as Omit<ContactMessageProps, 'contact_private_policy'>;

      const data = (await sendRequest(url, 'POST', body)) as {
        snackbar: SnackbarProps;
        contact: ContactMessageProps;
      };

      const snackbar = data.snackbar as SnackbarProps;
      dispatch(snackbarAction(snackbar));
      setConfetti(true);

      resetFormFunc();
    } catch (error) {
      if (error instanceof AxiosError) {
        dispatch(
          snackbarAction({
            status: 'error',
            timeout: 8000,
            message: error.response?.data.message,
          })
        );
      }
    }
  };

  const resetFormFunc = () => {
    setFormData(initialState, false);
    datePickerRef.current?.resetDate();
    checkboxRef.current?.reset();
    multiCheckboxRef.current?.reset();
  };

  return (
    <SectionLayout
      id="contact"
      title="Formularz kontaktowy"
      decorator="separator_4"
      divider
      className={classes.form}
    >
      <Box p={{ xs: 2, sm: 4, md: 6, lg: 8 }}>
        <form id="contact-form">
          <Input
            label="Imię*"
            id="contact_name"
            name="contact-name"
            type="text"
            onInput={inputHandler}
            validators={[VALIDATOR_REQUIRE(), VALIDATOR_MINLENGTH(3)]}
            errorText="imie powinno mieć więcej niż 3 ale mniej niż 20 znaki"
            fontFamily="barlow-italic-bold"
            inputFontFamily="barlow"
            color="--dark-gold"
            value={formState.inputs.contact_name.value as string}
            fullWidth
            column
            letterSpacing={1.3}
            formLabel={{
              size: sm ? 'x-small' : 'small',
              color: '--dark-gold',
            }}
          />
          <Input
            label="E-mail*"
            id="contact_email"
            name="contact-email"
            type="text"
            onInput={inputHandler}
            validators={[VALIDATOR_REQUIRE(), VALIDATOR_EMAIL()]}
            errorText="wpisz poprawny e-mail"
            fontFamily="barlow-italic-bold"
            inputFontFamily="barlow"
            color="--dark-gold"
            value={formState.inputs.contact_email.value as string}
            fullWidth
            column
            letterSpacing={1.3}
            formLabel={{
              size: sm ? 'x-small' : 'small',
              color: '--dark-gold',
            }}
          />
          <Input
            label="Numer Telefonu*"
            id="contact_phone"
            name="contact-phone"
            type="number"
            value={formState.inputs.contact_phone.value as string}
            onInput={inputHandler}
            validators={[
              VALIDATOR_REQUIRE(),
              VALIDATOR_PHONE(),
              VALIDATOR_MINLENGTH(6),
              VALIDATOR_MAXLENGTH(20),
            ]}
            errorText="Podaj prawidłowy numer telefonu"
            fontFamily="barlow-italic-bold"
            inputFontFamily="barlow"
            color="--dark-gold"
            column
            letterSpacing={1.3}
            formLabel={{ size: sm ? 'x-small' : 'small', color: '--dark-gold' }}
          />
          <Input
            label="Miejscowość w której ma odbyć się uroczystość*"
            id="contact_place"
            name="contact-place"
            type="text"
            value={formState.inputs.contact_place.value as string}
            onInput={inputHandler}
            validators={[
              VALIDATOR_REQUIRE(),
              VALIDATOR_MINLENGTH(3),
              VALIDATOR_MAXLENGTH(20),
            ]}
            errorText="miejscowość powinna mieć więcej niż 3 ale mniej niż 20 znaki"
            formLabel={{ size: sm ? 'x-small' : 'small', color: '--dark-gold' }}
            fontFamily="barlow-italic-bold"
            inputFontFamily="barlow"
            color="--dark-gold"
            letterSpacing={1.3}
            column
          />
          <DatePicker
            label="Data uroczystości*"
            ref={datePickerRef}
            onDateChange={handleDate}
            className={classes.action}
            slotProps={{
              textField: { variant: 'standard' },
              actionBar: {
                className: `${classes.action}`,
                hidden: false,
              },
              layout: {
                sx: {
                  backgroundColor: 'var(--white-shadow)',
                  border: '1.5px solid var(--dark-gold)',
                  fontFamily: 'barlow-italic-bold',
                  mt: 2,
                  color: '--dark-gold',
                  '& button': {
                    color: 'var(--dark-gold)',
                    fontFamily: 'barlow-italic-bold',
                  },
                },
              },
            }}
            input={{
              fontFamily: 'barlow-italic-bold',
              color: '--dark-gold',
              letterSpacing: 1.3,
            }}
            customLabel={{
              fontFamily: 'barlow-italic-bold',
              color: '--dark-gold',
              focusedColor: '--dark-gold',
              letterSpacing: 1.3,
            }}
          />
          <Textarea
            id="additional_information"
            name="Dodatkowe Informacje"
            label="Dodatkowe Informacje"
            errorText="Nieprawidłowy tekst"
            fontFamily="barlow-italic-bold"
            valid
            fullWidth={!md}
            color="--dark-gold"
            value={formState.inputs.additional_information.value as string}
            formLabel={{ color: '--dark-gold' }}
            validators={[VALIDATOR_MINLENGTH(0)]}
            onInput={inputHandler}
            rows={2}
            inputClassName={classes.textarea}
            borderBottomColor="--dark-gold"
          ></Textarea>
          <MultipleCheckbox
            title="Rodzaj"
            ref={multiCheckboxRef}
            singleSelect
            labelColor="--dark-gold"
            variant="radio"
            labelSize={sm ? 'xx-small' : 'x-small'}
            size="small"
            row
            fontFamily="barlow-italic-bold"
            textTransform="capitalize"
            letterSpacing={1}
            values={[
              'wesele',
              'event',
              'uroczystość',
              'atrakcje',
              'studniówka',
              'inne',
            ]}
            onSelectionChange={handleSelectionChange}
          />
          <Box
            height={100}
            maxWidth={500}
            display="flex"
            alignItems="center"
            className={classes.polity}
          >
            <Box height={80} display="flex" alignItems="center">
              <Checkbox
                id="private-policy"
                ref={checkboxRef}
                size="small"
                onSelectionChange={handleChecked}
              />
            </Box>

            <Typography
              fontFamily="barlow-italic"
              size="xx-small"
              component="label"
              htmlFor="private-policy"
            >
              * Zgadzam się na przesyłanie informacji handlowych za
              pośrednictwem środków komunikacji elektronicznej lub na kontakt
              telefoniczny.{' '}
              <LinkComponent
                fontFamily="barlow-italic-bold"
                to="/polityka-prywatnosci"
                color="--dark-gold"
                label="polityka prywatności"
              >
                Polityka prywatności.
              </LinkComponent>{' '}
            </Typography>
          </Box>
          {isLoading ? (
            <Loader />
          ) : (
            <Tooltip
              title="Wypełnij wszystkie nezbędne pola!"
              placement={isMobile ? 'top' : 'right'}
              className={classes.tooltip}
              PopperProps={{
                disablePortal: true,
              }}
              componentsProps={{ popper: { color: 'var(--main-gold)' } }}
              arrow
              onClose={closeTooltipHandler}
              open={tooltipOpen}
              enterDelay={300}
              leaveDelay={1800}
              color="#957500"
            >
              <Button
                fullWidth={isMobile}
                variant="outlined"
                size="x-small"
                type="submit"
                color="--dark-gold"
                onClick={handleSubmit}
              >
                Wyślij Zapytanie
              </Button>
            </Tooltip>
          )}
        </form>
      </Box>
      {confetti && (
        <Confetti
          className={classes.confetti}
          width={window.innerWidth}
          height={window.innerHeight}
          numberOfPieces={2000}
          recycle={false}
        />
      )}
    </SectionLayout>
  );
};
