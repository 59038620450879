import { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { AreaProps, CityItemProps } from 'src/types/event-area-types';

import { useGetArea } from './useGetArea';

export const useCityFilter = () => {
  const [eventArea, setEventArea] = useState<AreaProps[]>([]);
  const [filterCity, setCity] = useState<CityItemProps>({
    name: {
      nominativus: 'Olsztyn',
      locativus: 'Olsztynie',
      genetivus: 'Olsztyna',
    },
    path: 'olsztyn',
    addressLocality: '10-057',
    selectedArea: true,
  });

  const location = useLocation();
  const pathname = location.pathname;
  const segments = pathname.split('/').filter(Boolean);
  const cityName = segments[segments.length - 1];
  const data = useGetArea('/eventArea.json') as AreaProps[] | undefined;

  useEffect(() => {
    if (data) {
      setEventArea(data);
    }
  }, [data]);

  const placeIncludeCity = useMemo(() => {
    return eventArea.find((area) => {
      return area.citis.some((city) => {
        return city.path === cityName;
      });
    });
  }, [eventArea, cityName]);

  const cityObject = useMemo(() => {
    return placeIncludeCity?.citis.find((city) => {
      return city.path === cityName;
    });
  }, [placeIncludeCity, cityName]);

  useEffect(() => {
    if (cityObject) {
      setCity(cityObject);
    }
  }, [cityObject]);

  return { filterCity, eventArea };
};

export default useCityFilter;
