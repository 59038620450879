/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, BoxCustomProps } from '@components/UI';
import React, { FC, memo, useEffect, useState } from 'react';
import { LinkProps, NavLink } from 'react-router-dom';
import styled, { keyframes, css } from 'styled-components';

import { ReactComponent as ImportedLine } from './erulers_logo.svg';

interface OrnamentProps extends BoxCustomProps {
  duration?: number;
  delay?: number;
  height?: number;
}

interface LogoProps extends OrnamentProps {
  to?: LinkProps['to'];
}

const Logo: FC<LogoProps> = ({
  delay,
  color,
  height,
  duration,
  to,
  ...boxProps
}) => {
  return (
    <Box {...boxProps} height="auto" aria-label="Strona główna">
      {to ? (
        <NavLink to={to} aria-label="Strona główna">
          <OrnamentShape
            delay={delay}
            color={color}
            height={height}
            duration={duration}
          />
        </NavLink>
      ) : (
        <OrnamentShape
          delay={delay}
          color={color}
          height={height}
          duration={duration}
        />
      )}
    </Box>
  );
};

const OrnamentShape: React.FC<OrnamentProps> = ({
  color = '#ccc',
  duration = 10,
  delay = 1,
  height = 150,
}) => {
  const [pathLength, setPathLength] = useState<number>(0);
  const [pathLength2, setPathLength2] = useState<number>(0);
  const [pathLength3, setPathLength3] = useState<number>(0);

  const id1 = 'ornamentPath';
  const id2 = 'ornamentPath2';
  const id3 = 'ornamentPath3';

  const delays = { [id1]: delay, [id2]: delay * 3, [id3]: delay * 5 } as {
    [key: string]: number;
  };

  useEffect(() => {
    const path1 = document.getElementById(id1) as unknown as SVGPathElement;
    if (path1) {
      const length = path1.getTotalLength();
      setPathLength(length / 2);
    }
  }, []);

  useEffect(() => {
    const path2 = document.getElementById(id2) as unknown as SVGPathElement;
    if (path2) {
      const length = path2.getTotalLength();
      setPathLength2(length / 2);
    }
  }, []);
  useEffect(() => {
    const path3 = document.getElementById(id3) as unknown as SVGPathElement;
    if (path3) {
      const length = path3.getTotalLength();
      setPathLength3(length / 2);
    }
  }, []);

  return (
    <Line
      pathLength={pathLength}
      pathlength2={pathLength2}
      pathlength3={pathLength3}
      color={`var(${color})`}
      duration={duration}
      delays={delays}
      height={height}
    />
  );
};

interface LineProps {
  pathLength: number;
  pathlength2: number;
  pathlength3: number;
  color: string;
  duration?: number;
  delays?: { [key: string]: number };
  height?: number;
}

const ornamentPath = (length: number, color: string) => {
  return keyframes`
    0% {
      fill-opacity: 0;
      stroke-opacity: 1;
      fill: transparent;
      stroke-dashoffset: ${length}px;
    }
    80% {
      stroke-opacity: 1;
      fill-opacity: 0.5;
      fill: ${color};
      stroke-dashoffset: 0;
    }
    100% {
      stroke-opacity: 1;
      fill-opacity: 0.7;
      fill: ${color};
      stroke-dashoffset: 0;
    }
  `;
};

const Line = styled(ImportedLine)<LineProps>`
  height: ${(props: any) => {
    return props.height;
  }}px;
  display: block;
  margin: auto;

  & #ornamentPath {
    stroke: ${(props) => {
      return props.color;
    }};
    fill: ${(props) => {
      return props.color;
    }};
    stroke-dasharray: ${(props) => {
      return props.pathLength;
    }}px;
    stroke-dashoffset: ${(props) => {
      return props.pathLength;
    }}px;
    stroke-opacity: 0;
    fill-opacity: 0;
    animation: ${(props) => {
      return css`
        ${ornamentPath(props.pathLength, props.color)}
        ${props.duration}s linear
      `;
    }};
    animation-fill-mode: forwards;
  }

  & #ornamentPath2 {
    stroke: ${(props) => {
      return props.color;
    }};
    fill: ${(props) => {
      return props.color;
    }};
    stroke-dasharray: ${(props) => {
      return props.pathlength2;
    }}px;
    stroke-dashoffset: ${(props) => {
      return props.pathlength2;
    }}px;
    stroke-opacity: 0;
    fill-opacity: 0;
    animation: ${(props) => {
      return css`
        ${ornamentPath(props.pathlength2, props.color)}
        ${props.duration}s linear
      `;
    }};
    animation-fill-mode: forwards;
  }

  & #ornamentPath3 {
    stroke: ${(props) => {
      return props.color;
    }};
    fill: ${(props) => {
      return props.color;
    }};
    stroke-dasharray: ${(props) => {
      return props.pathlength3;
    }}px;
    stroke-dashoffset: ${(props) => {
      return props.pathlength3;
    }}px;
    stroke-opacity: 0;
    fill-opacity: 0;
    animation: ${(props) => {
      return css`
        ${ornamentPath(props.pathlength3, props.color)}
        ${props.duration! / 2}s linear
      `;
    }};
    animation-fill-mode: forwards;
  }

  ${(props) => {
    return (
      props.delays &&
      Object.entries(props.delays).map(([id, delay]) => {
        return css`
          & #${id} {
            animation-delay: ${delay}s;
          }
        `;
      })
    );
  }}
`;

export default memo(Logo);
