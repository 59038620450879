import { useEffect } from 'react';

function useScrollToSection(sectionId: string, offset = 0) {
  const scrollToSection = () => {
    const sectionElement = document.getElementById(sectionId);
    if (sectionElement) {
      window.scrollTo({
        top: sectionElement.offsetTop + offset,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    const handleClickToSection = () => {
      scrollToSection();
    };

    const sectionElement = document.getElementById(sectionId);
    if (sectionElement) {
      sectionElement.addEventListener('click', handleClickToSection);
    }

    return () => {
      if (sectionElement) {
        sectionElement.removeEventListener('click', handleClickToSection);
      }
    };
  }, [sectionId, offset]);

  return scrollToSection;
}

export default useScrollToSection;
