import MessengerCustomerChat from 'react-messenger-customer-chat';

export const MessengerChat = () => {
  return (
    <MessengerCustomerChat
      pageId={process.env.REACT_APP_pageId}
      appId={process.env.REACT_APP_appId}
      language="pl_PL"
    />
  );
};
