import { useMediaQuery } from '@theme/hooks/useMediaQuery';
import { useEffect, useState } from 'react';

export const useMobile = () => {
  const mobile = useMediaQuery('sm');
  const [isMobile, setIsMobile] = useState(mobile);

  useEffect(() => {
    setIsMobile(mobile);
  }, [mobile]);

  return isMobile;
};
