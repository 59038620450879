import { useMobile } from '@hooks/useMobile';
import { Stack } from '@mui/material';

import { TypographyProps } from '@mui/material/Typography/Typography';
import {
  DatePicker as MUIDatePicker,
  DateField as MUIDateField,
  DatePickerProps,
} from '@mui/x-date-pickers';
import { useAppDispatch } from '@store/hooks';
import { snackbarAction } from '@store/slices/snackbarReducer';
import React, { FC, useState, useImperativeHandle, forwardRef } from 'react';

import { Typography, fontSizeMap } from '..';
import { CustomColors, FontFamilies, SizesType } from '../types';

interface InputPickerProps {
  fontFamily?: FontFamilies;
  color?: CustomColors;
  size?: SizesType;
  letterSpacing?: TypographyProps['letterSpacing'];
  textTransform?: TypographyProps['textTransform'];
}

interface PickerProps extends DatePickerProps<Date> {
  onDateChange: (date: string) => void;
  input?: InputPickerProps;
  customLabel?: InputPickerProps & { focusedColor?: CustomColors };
  errorText?: string;
  touched?: boolean;
  sx?: TypographyProps['sx'];
}

export interface DatePickerHandles {
  resetDate: () => void;
}

export const DatePicker = forwardRef<DatePickerHandles, PickerProps>(
  (props, ref) => {
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const {
      onDateChange,
      input,
      customLabel,
      errorText,
      touched,
      ...datePickerProps
    } = props;

    const [error, setError] = useState(false);

    const isMobile = useMobile();
    const dispatch = useAppDispatch();

    const handleDateChange = (newDate: Date | null) => {
      setSelectedDate(newDate);

      if (newDate) {
        onDateChange(newDate.toLocaleDateString());
        setError(false); // Brak błędu, jeśli data jest wybrana
      } else {
        setError(true); // Ustaw błąd, jeśli data nie jest wybrana
      }
    };

    useImperativeHandle(ref, () => {
      return {
        resetDate() {
          setSelectedDate(null);
        },
      };
    });

    const isError = error && touched;

    const maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() + 3);
    const currentDate = new Date();

    return (
      <>
        <Stack spacing={2}>
          <MUIDatePicker
            {...datePickerProps}
            format="dd-MM-yyyy"
            value={selectedDate}
            minDate={currentDate}
            maxDate={maxDate}
            onError={(error) => {
              dispatch(
                snackbarAction({
                  status: 'error',
                  message: `błąd picker ${error}`,
                })
              );
            }}
            sx={{
              '& input': {
                color: `var(${input?.color})`,
                fontFamily: input?.fontFamily,
                fontSize: fontSizeMap[input?.size as SizesType],
                letterSpacing: input?.letterSpacing,
                textTransform: input?.textTransform,
                ml: 2,
              },
              '& label': {
                color: `var(${customLabel?.color})`,
                fontFamily: customLabel?.fontFamily,
                fontSize: fontSizeMap[customLabel?.size as SizesType],
                letterSpacing: customLabel?.letterSpacing,
                textTransform: customLabel?.textTransform,
                ml: 1,
              },
              '& .Mui-focused': {
                color: `var(${customLabel?.focusedColor}) !important`,
              },
              '& button': {
                mr: 1,
                color: `var(${input?.color})`,
              },
              '& .MuiDateCalendar-root': {
                bgcolor: 'ActiveBorder',
                padding: '20px',
              },
            }}
            onChange={handleDateChange}
          />
        </Stack>
        <Typography
          component="p"
          size={isMobile ? 'xx-small' : 'x-small'}
          color="--cancel"
          mt={1}
          mb={2}
          ml={2}
        >
          {isError ? errorText : ' '}
        </Typography>
      </>
    );
  }
);

export const DateField: FC<PickerProps> = ({
  onDateChange,
  input,
  customLabel,
  ...datePickerProps
}) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const handleDateChange = (newDate: Date | null) => {
    setSelectedDate(newDate);
    if (newDate) {
      onDateChange(newDate.toLocaleString());
    }
  };

  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() + 3);
  const currentDate = new Date();

  return (
    <>
      <Stack spacing={2}>
        <MUIDateField
          {...datePickerProps}
          minDate={currentDate}
          maxDate={maxDate}
          value={selectedDate}
          // sx={{
          //   '& input': {
          //     color: `var(${input?.color})`,
          //     fontFamily: input?.fontFamily,
          //     fontSize: fontSizeMap[input?.size as SizesType],
          //     letterSpacing: input?.letterSpacing,
          //     textTransform: input?.textTransform,
          //     ml: 2,
          //   },
          //   '& label': {
          //     color: `var(${customLabel?.color})`,
          //     fontFamily: customLabel?.fontFamily,
          //     fontSize: fontSizeMap[customLabel?.size as SizesType],
          //     letterSpacing: customLabel?.letterSpacing,
          //     textTransform: customLabel?.textTransform,
          //   },
          //   '& .Mui-focused': {
          //     color: `var(${customLabel?.focusedColor}) !important`,
          //   },
          //   '& button': {
          //     mr: 1,
          //     color: `var(${input?.color})`,
          //   },
          // }}
          onChange={handleDateChange}
        />
      </Stack>
    </>
  );
};
