import { ReactNode } from 'react';
import { createPortal } from 'react-dom';

import classes from './portal.module.scss';

type PortalType = 'snackbar' | 'portal' | 'backdrop';

interface PortalProps {
  children: ReactNode;
  portalType?: PortalType;
  onClose?: () => void;
}

export const Portal = ({
  children,
  portalType = 'portal',
  onClose,
}: PortalProps) => {
  const showBackdrop = portalType !== 'snackbar';
  const portal = createPortal(
    children,
    document.getElementById(portalType) as HTMLElement
  );
  const backdrop = createPortal(
    <div className={classes.backdrop} onClick={onClose} />,
    document.getElementById(portalType) as HTMLElement
  );
  return (
    <div className={classes.portal}>
      {showBackdrop && backdrop}
      {portal}
    </div>
  );
};
