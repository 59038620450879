interface NavigationPath {
  name: string;
  path: string;
}

export interface NavigationItem extends NavigationPath {
  subMenu?: NavigationPath[];
}

export interface NavigationPathProps {
  navigation: NavigationItem[];
}

export const navigation: NavigationPathProps['navigation'] = [
  {
    name: 'Home',
    path: '/',
  },
  {
    name: 'O nas',
    path: '/o-nas',
  },
  {
    name: 'Usługi',
    path: '/uslugi',
    subMenu: [
      {
        name: 'Wesela',
        path: '/dj-na-wesele/olsztyn',
      },
      {
        name: 'Eventy',
        path: '/agencja-eventowa/olsztyn',
      },
      {
        name: 'Imprezy Okolicznościowe',
        path: '/dj-na-impreze/olsztyn',
      },
      {
        name: 'Studniówki',
        path: '/dj-na-studniowke/olsztyn',
      },
      // {
      //   name: 'Bale 8 Klas',
      //   path: '/dj-na-bal-8-klas-olsztyn',
      // },
    ],
  },
  {
    name: 'Atrakcje',
    path: '/atrakcje',
    subMenu: [
      {
        name: 'Fotolustro',
        path: '/fotobudka/olsztyn',
      },
      {
        name: 'Hot Casino',
        path: '/kasyno/olsztyn',
      },
      {
        name: 'Miny Dymne',
        path: '/kolorowy-dym-na-wesele/olsztyn',
      },
      {
        name: 'Taniec w chmurach',
        path: '/ciezki-dym/olsztyn',
      },
      {
        name: 'Fontanny iskier',
        path: '/fontanny-iskier/olsztyn',
      },
      {
        name: 'Napis Love',
        path: '/napis-love/olsztyn',
      },
      {
        name: 'Napis Miłość',
        path: '/napis-milosc/olsztyn',
      },
      {
        name: 'Neonowy napis',
        path: '/neonowy-napis-na-wesele/olsztyn',
      },
      {
        name: 'Dekoracja Światłem',
        path: '/dekoracja-swiatlem/olsztyn',
      },
    ],
  },
  // {
  //   name: 'Opinie',
  //   path: 'dj-na-wesele-opinie',
  // },
  {
    name: 'Kontakt',
    path: 'kontakt',
  },
];
