import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { UserProps } from 'src/types/user';

import { SliceName } from '../enums';

export type UserState = Omit<UserProps, 'user_password'>;

const initialState: UserState = {
  user_email: '',
  user_image: '',
  user_name: '',
  user_nick: '',
  user_phone: '',
  user_surname: '',
  user_type: 'guest',
  user_status: null,
  isAdmin: false,
  user_token: null,
};

const userSlice = createSlice({
  name: SliceName.USER_DATA,
  initialState,
  reducers: {
    userAction: (state, action: PayloadAction<UserState>) => {
      state.user_email = action.payload.user_email;
      state.user_image = action.payload.user_image;
      state.user_name = action.payload.user_name;
      state.user_nick = action.payload.user_nick;
      state.user_phone = action.payload.user_phone;
      state.user_status = action.payload.user_status;
      state.user_surname = action.payload.user_surname;
      state.user_type = action.payload.user_type;
      state.isAdmin = action.payload.isAdmin;
      state.user_token = action.payload.user_token;
    },
    userLogout: (state) => {
      state.user_email = initialState.user_email;
      state.user_image = initialState.user_image;
      state.user_name = initialState.user_name;
      state.user_nick = initialState.user_nick;
      state.user_phone = initialState.user_phone;
      state.user_status = initialState.user_status;
      state.user_surname = initialState.user_surname;
      state.user_type = initialState.user_type;
      state.isAdmin = initialState.isAdmin;
      state.user_token = initialState.user_token;
    },
  },
});

export const { userAction, userLogout } = userSlice.actions;

export const UserActions = userSlice.actions;

export default userSlice.reducer;
