import React from 'react';

import { Swiper, SwiperOptions, SwiperSlide, SwiperTypes } from '../Swiper';

export interface CarouselProps extends SwiperOptions {
  containerStartContent?: React.ReactNode;
  containerEndContent?: React.ReactNode;
  wrapperStartContent?: React.ReactNode;
  wrapperEndContent?: React.ReactNode;
  slides: React.ReactNode[] | string[];
  className?: string;
  onSlideChange?: (swiper: SwiperTypes) => void;
}

export const Carousel = ({
  containerStartContent,
  containerEndContent,
  wrapperStartContent,
  wrapperEndContent,
  slides,
  className,
  ...rest
}: CarouselProps) => {
  return (
    <Swiper className={className} {...rest}>
      {slides.map((slide, index) => {
        return <SwiperSlide key={index}>{slide}</SwiperSlide>;
      })}
      {containerStartContent && (
        <span slot="container-start">{containerStartContent}</span>
      )}
      {containerEndContent && (
        <span slot="container-end">{containerEndContent}</span>
      )}
      {wrapperStartContent && (
        <span slot="wrapper-start">{wrapperStartContent}</span>
      )}
      {wrapperEndContent && <span slot="wrapper-end">{wrapperEndContent}</span>}
    </Swiper>
  );
};
