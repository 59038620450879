import { CardMedia, CardMediaProps, BoxProps } from '@mui/material';
import React, { CSSProperties, FC } from 'react';
import { LazyLoadImageProps } from 'react-lazy-load-image-component';

interface ImageProps extends CardMediaProps {
  alt: string;
  height?: BoxProps['height'];
  width?: BoxProps['width'];
}

export const Image: FC<ImageProps> = ({
  component = 'img',
  src,
  alt,
  height,
  width,
  sx,
  ...props
}) => {
  return (
    <CardMedia
      {...props}
      component={component}
      src={src}
      alt={alt}
      sx={{ ...sx, height, width }}
    />
  );
};

export interface LazyImageProps extends LazyLoadImageProps {
  objectPosition?: CSSProperties['objectPosition'];
  objectFit?: CSSProperties['objectFit'];
  maxHeight?: CSSProperties['maxHeight'];
  backgroundAttachment?: CSSProperties['backgroundAttachment'];
}
export const LazyImage: FC<LazyImageProps> = ({
  loading = 'lazy',
  objectFit = 'cover',
  objectPosition = 'center',
  height = 'auto',
  width = '100%',
  maxHeight,
  backgroundAttachment,
  className,
  ...props
}) => {
  return (
    <img
      className={className}
      loading={loading}
      width={width}
      height={height}
      style={{
        ...props.style,
        objectFit,
        objectPosition,
        maxHeight,
        backgroundAttachment,
      }}
      {...props}
    />
  );
};
