import { fontSizeMap } from '@components/UI';
import { styled, ListItemText } from '@mui/material';

import { CustomListItemTextProps } from '../types';

export const ListItemTextStyled = styled(ListItemText, {
  shouldForwardProp: (prop) => {
    return prop !== 'color' && prop !== 'fontFamily' && prop !== 'uppercase';
  },
})<CustomListItemTextProps>(({
  color = '--black',
  size = 'medium',
  textAlign = 'left',
  fontFamily,
  letterSpacing,
  uppercase,
}) => {
  return {
    color: `var(${color})`,
    textTransform: uppercase ? 'uppercase' : 'initial',
    '& .MuiListItemText-primary': {
      fontFamily,
      letterSpacing,
      fontSize: fontSizeMap[size],
      textAlign,
    },
  };
});
