import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { SliceName } from '../enums';

const initialState = {
  menuDrawerIsOpen: false,
};

const globalStateSlice = createSlice({
  name: SliceName.GLOBAL_STATE,
  initialState,
  reducers: {
    mobileToggler: (state) => {
      state.menuDrawerIsOpen = !state.menuDrawerIsOpen;
    },
    drawerFunc: (state, action: PayloadAction<boolean>) => {
      state.menuDrawerIsOpen = action.payload;
    },
  },
});

export const { mobileToggler, drawerFunc } = globalStateSlice.actions;

export const GlobalStateActions = globalStateSlice.actions;

export default globalStateSlice.reducer;
