import { useCallback, useReducer } from 'react';

export interface FormState<T> {
  inputs: T;
  isValid: boolean;
}

type InputState = {
  value: string | File | null | boolean;
  isValid: boolean;
};

export type FormInputs = Record<string, InputState>;

type ActionForm<T extends FormInputs> =
  | {
      type: 'INPUT_CHANGE';
      inputId: string;
      value: string | File | null | boolean | Date;
      isValid: boolean;
    }
  | {
      type: 'INPUT_FILE';
      inputId: string;
      value: File | null;
      isValid: boolean;
    }
  | { type: 'SET_DATA'; inputs: T; formIsValid: boolean };

const formReducer = <T extends FormInputs>(
  state: FormState<T>,
  action: ActionForm<T>
): FormState<T> => {
  switch (action.type) {
    case 'INPUT_CHANGE': {
      let formIsValid = true;
      const updatedInputs = {
        ...state.inputs,
        [action.inputId]: { value: action.value, isValid: action.isValid },
      };

      for (const inputId in updatedInputs) {
        formIsValid = formIsValid && updatedInputs[inputId].isValid;
      }

      return {
        ...state,
        inputs: updatedInputs,
        isValid: formIsValid,
      };
    }
    case 'SET_DATA': {
      return {
        inputs: action.inputs,
        isValid: action.formIsValid,
      };
    }
    default: {
      return state;
    }
  }
};

export const useForm = <T extends FormInputs>(
  initialInputs: T,
  initialFormValidity: boolean
) => {
  const [formState, dispatch] = useReducer(formReducer<T>, {
    inputs: initialInputs,
    isValid: initialFormValidity,
  });

  const inputHandler = useCallback(
    (
      id: string,
      value: string | File | null | boolean | Date,
      isValid: boolean
    ) => {
      dispatch({
        type: 'INPUT_CHANGE',
        inputId: id,
        value,
        isValid,
      });
    },
    []
  );

  const setFormData = useCallback((inputs: T, formIsValid: boolean) => {
    dispatch({
      type: 'SET_DATA',
      inputs,
      formIsValid,
    });
  }, []);

  return { formState, inputHandler, setFormData };
};
