import styled from 'styled-components';

export const fontSizeMap = {
  'xx-small': '0.7rem',
  'x-small': '0.85rem',
  small: '1rem',
  medium: '1.15rem',
  large: '1.5rem',
  'x-large': '2rem',
  'xx-large': '2.5rem',
  'xxx-large': '3rem',
  title: '4rem',
  'x-title': '6rem',
};

export const styledComponent = styled;
