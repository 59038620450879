import * as React from 'react';
import { SVGProps } from 'react';

const SvgRatingArrow = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width || 24}
      height={props.height || 24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      viewBox={props.viewBox || '0 0 24 24'}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.5 33.5V2.828C.5 1.047 2.654.154 3.914 1.414l30.672 30.672a2 2 0 0 1 0 2.828L3.914 65.586C2.654 66.846.5 65.953.5 64.172V33.5Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default SvgRatingArrow;
