import { useAppDispatch } from '@store/hooks';
import { snackbarAction } from '@store/slices/snackbarReducer';
import React, { memo, useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';

interface VideoProps {
  src: string;
  autoplay?: boolean;
  fullwidth?: boolean;
  maxHeight?: number;
  className?: string;
  autoplayWhenVisible?: boolean;
  muted?: boolean;
  title: string;
  description: string;
  thumbnailUrl: string;
  uploadDate?: string;
  duration: string;
}

const VideoPlayer: React.FC<VideoProps> = ({
  src,
  autoplay = false,
  fullwidth = false,
  maxHeight,
  className,
  autoplayWhenVisible = false,
  muted = false,
  title,
  description,
  thumbnailUrl: thumbnailUrl,
  uploadDate = '2024-04-20T08:00:00+00:00',
  duration,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [userInteracted, setUserInteracted] = useState(false);
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.4,
  });

  const dispatch = useAppDispatch();

  useEffect(() => {
    const handleUserInteraction = () => {
      if (!userInteracted) {
        setUserInteracted(true);
        if (videoRef.current && autoplay) {
          videoRef.current.play();
        }
      }
    };

    if (!userInteracted) {
      window.addEventListener('click', handleUserInteraction);
      return () => {
        window.removeEventListener('click', handleUserInteraction);
      };
    }
  }, [userInteracted]);

  useEffect(() => {
    if (inView && autoplayWhenVisible && videoRef.current) {
      const playPromise = videoRef.current.play();

      if (playPromise !== undefined) {
        playPromise.then(() => {});
        dispatch(
          snackbarAction({
            status: 'edited',
            message: 'Dźwięki zostały wyciszone automatycznie',
          })
        );
      }
    }
  }, [inView, autoplayWhenVisible]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (videoRef.current) {
        if (inView && autoplayWhenVisible) {
          videoRef.current.volume = 1;
          videoRef.current.play();
        } else {
          videoRef.current.pause();
          videoRef.current.volume = 0;
        }
      }
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [inView, autoplayWhenVisible]);

  const containerStyle: React.CSSProperties = {
    position: 'relative',
    width: fullwidth ? '100%' : 'auto',
    maxWidth: fullwidth ? '100%' : 'none',
    maxHeight: maxHeight ? `${maxHeight}px` : 'none',
    margin: '0 auto',
  };

  const videoStyle: React.CSSProperties = {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  };

  return (
    <div
      className={className}
      style={containerStyle}
      ref={ref}
      itemProp="video"
      itemScope
      itemType="http://schema.org/VideoObject"
    >
      <meta itemProp="name" content={title} />
      <meta itemProp="duration" content={duration} />
      <meta itemProp="thumbnailUrl" content={thumbnailUrl} />
      <meta itemProp="contentURL" content={src} />
      <meta itemProp="embedURL" content={src} />
      <meta itemProp="description" content={description} />
      <meta itemProp="uploadDate" content={uploadDate} />

      <video
        muted={muted}
        ref={videoRef}
        style={videoStyle}
        controls={true}
        loop={true}
        autoPlay={autoplay}
      >
        <source src={src} type="video/mp4" />
        <track kind="captions" srcLang="pl" label={title}></track>
      </video>
      <h1>{title}</h1>
      <p>{description}</p>
    </div>
  );
};

export default memo(VideoPlayer);
