import { CustomColors } from '@components/UI/types';
import clsx from 'clsx';
import React, { FC, memo } from 'react';

import { NavigationPathProps } from '../navigation-paths';
import classes from '../style.module.scss';

import { CustomNavLinkProps, StyledNavLinkRouter } from './NavLink.styled';

interface CustomNavLink extends CustomNavLinkProps {
  subMenu?: NavigationPathProps['navigation'];
  hoverColor?: CustomColors;
}

const NavLink: FC<CustomNavLink> = ({
  children,
  color,
  isLogout,
  hoverColor,
  ...props
}) => {
  return (
    <StyledNavLinkRouter
      className={clsx(classes.link)}
      hoverColor={hoverColor}
      color={color}
      isLogout={isLogout}
      {...props}
    >
      {children}
    </StyledNavLinkRouter>
  );
};

export default memo(NavLink);
