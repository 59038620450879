import { ErrorBoundary } from '@pages/error/ErrorBoudary';
import { RootLayout } from '@router/root/RootLayout';
import { lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';

import { AdminLayout } from './root/AdminLayout';

const AboutPage = lazy(() => {
  return import('../pages/about/AboutPage');
});
const HomePage = lazy(() => {
  return import('../pages/home/HomePage');
});
const ServicesPage = lazy(() => {
  return import('../pages/services/ServicesPage/ServicesPage');
});
const CannonicalWeddingsPage = lazy(() => {
  return import('../pages/services/WeddingsPage/CannonicWeddingPage');
});
const WeddingsPage = lazy(() => {
  return import('../pages/services/WeddingsPage/WeddingsPage');
});
const CanonicalEventsPage = lazy(() => {
  return import('../pages/services/EventsPage/CanonicEventPage');
});
const EventsPage = lazy(() => {
  return import('../pages/services/EventsPage/EventsPage');
});
const CelebrationsPage = lazy(() => {
  return import('../pages/services/CelebrationsPage/CelebrationsPage');
});
const CanonicalCelebrationsPage = lazy(() => {
  return import('../pages/services/CelebrationsPage/CanonicCelebrationPage');
});
const CanonicalPromPage = lazy(() => {
  return import('../pages/services/PromPage/CanonicalPromPage');
});
const PromPage = lazy(() => {
  return import('../pages/services/PromPage/PromPage');
});
const SchoolGraduationBall = lazy(() => {
  return import('../pages/services/SchoolGraduationBall/SchoolGraduationBall');
});
const ExtrasPage = lazy(() => {
  return import('../pages/extras/ExtrasPage/ExtrasPage');
});
const CanonicalMirrorPhotoBoothPage = lazy(() => {
  return import(
    '../pages/extras/MirrorPhotoBooth/CanonicalMirrorPhotoBoothPage'
  );
});
const MirrorPhotoBoothPage = lazy(() => {
  return import('../pages/extras/MirrorPhotoBooth/MirrorPhotoBoothPage');
});
const CanonicalSmokeBombsPage = lazy(() => {
  return import('../pages/extras/SmokeBombs/CanonicalSmokeBombsPage');
});
const SmokeBombsPage = lazy(() => {
  return import('../pages/extras/SmokeBombs/SmokeBombsPage');
});
const CanonicalLowFogPage = lazy(() => {
  return import('../pages/extras/LowFogPage/CanonicalLowFogPage');
});
const LowFogPage = lazy(() => {
  return import('../pages/extras/LowFogPage/LowFogPage');
});
const CanonicalWeddingSparksPage = lazy(() => {
  return import('../pages/extras/SparksPage/CanonicalSparkPage');
});
const WeddingSparksPage = lazy(() => {
  return import('../pages/extras/SparksPage/SparksPage');
});

const CanonicalNapisLovePage = lazy(() => {
  return import('../pages/extras/NapisLovePage/CanonicalNapisLovePage');
});
const NapisLovePage = lazy(() => {
  return import('../pages/extras/NapisLovePage/NapisLovePage');
});
const CanonicalNapisMiloscPage = lazy(() => {
  return import('../pages/extras/NapisMilosc/CanonicalNapisMilosc');
});
const NapisMiloscPage = lazy(() => {
  return import('../pages/extras/NapisMilosc/NapisMilosc');
});
const CanonicalNeonLightsPage = lazy(() => {
  return import('../pages/extras/NeonLightsPage/CanonicalNeonLightPage');
});
const NeonLightsPage = lazy(() => {
  return import('../pages/extras/NeonLightsPage/NeonLightsPage');
});
const CanonicalLightDecorPage = lazy(() => {
  return import('../pages/extras/LightDecorPage/CanonicalLightDecorPage');
});
const LightDecorPage = lazy(() => {
  return import('../pages/extras/LightDecorPage/LightDecorPage');
});

const CanonicalHotCasinoPage = lazy(() => {
  return import('../pages/extras/HotCasino/CanonicalHotCasino');
});
const HotCasinoPage = lazy(() => {
  return import('../pages/extras/HotCasino/HotCasino');
});
const ReviewsPage = lazy(() => {
  return import('../pages/Reviews/ReviewsPage');
});
const ContactPage = lazy(() => {
  return import('../pages/contact/ContactPage');
});
const DjsPage = lazy(() => {
  return import('../pages/landing-pages/Djs/DjsPage');
});
const McPage = lazy(() => {
  return import('../pages/landing-pages/McPage/McPage');
});
const InstrumentalistPage = lazy(() => {
  return import('../pages/landing-pages/Instrumentalist/InstrumentalistPage');
});
const AdminPage = lazy(() => {
  return import('../pages/admin/Admin');
});
const LoginPage = lazy(() => {
  return import('../pages/auth/login/LoginPage');
});
const SigninPage = lazy(() => {
  return import('../pages/auth/signin/SigninPage');
});
const PrivatePolicy = lazy(() => {
  return import('../pages/policy/PolicyPrivate');
});
const RangeOfActivity = lazy(() => {
  return import('../pages/range-of-activity/RangeOfActivity');
});

export const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    errorElement: <ErrorBoundary />,
    children: [
      { path: '/', element: <HomePage /> },
      { path: 'o-nas', element: <AboutPage /> },
      {
        path: 'uslugi',
        element: <ServicesPage />,
      },
      {
        path: 'uslugi/dj-na-wesele',
        element: <CannonicalWeddingsPage />,
      },
      {
        path: 'uslugi/dj-na-wesele/:city',
        element: <WeddingsPage />,
      },
      {
        path: 'dj-na-impreze/:city',
        element: <DjsPage />,
      },
      {
        path: 'wodzirej/:city',
        element: <McPage />,
      },
      {
        path: 'uslugi/akordeonista-saksofon/:city',
        element: <InstrumentalistPage />,
      },
      {
        path: 'uslugi/agencja-eventowa',
        element: <CanonicalEventsPage />,
      },
      {
        path: 'uslugi/agencja-eventowa/:city',
        element: <EventsPage />,
      },
      {
        path: 'uslugi/dj-na-impreze',
        element: <CanonicalCelebrationsPage />,
      },
      {
        path: 'uslugi/dj-na-impreze/:city',
        element: <CelebrationsPage />,
      },
      {
        path: 'uslugi/dj-na-studniowke',
        element: <CanonicalPromPage />,
      },
      {
        path: 'uslugi/dj-na-studniowke/:city',
        element: <PromPage />,
      },
      {
        path: 'uslugi/dj-na-bal-8-klas/:city',
        element: <SchoolGraduationBall />,
      },
      {
        path: 'atrakcje',
        element: <ExtrasPage />,
      },
      {
        path: 'atrakcje/fotobudka',
        element: <CanonicalMirrorPhotoBoothPage />,
      },
      {
        path: 'atrakcje/fotobudka/:city',
        element: <MirrorPhotoBoothPage />,
      },
      {
        path: '/atrakcje/kolorowy-dym-na-wesele',
        element: <CanonicalSmokeBombsPage />,
      },
      {
        path: '/atrakcje/kolorowy-dym-na-wesele/:city',
        element: <SmokeBombsPage />,
      },
      {
        path: 'atrakcje/ciezki-dym',
        element: <CanonicalLowFogPage />,
      },
      {
        path: 'atrakcje/ciezki-dym/:city',
        element: <LowFogPage />,
      },
      {
        path: 'atrakcje/fontanny-iskier',
        element: <CanonicalWeddingSparksPage />,
      },
      {
        path: 'atrakcje/fontanny-iskier/:city',
        element: <WeddingSparksPage />,
      },
      {
        path: 'atrakcje/napis-love',
        element: <CanonicalNapisLovePage />,
      },
      {
        path: 'atrakcje/napis-love/:city',
        element: <NapisLovePage />,
      },
      {
        path: 'atrakcje/napis-milosc',
        element: <CanonicalNapisMiloscPage />,
      },
      {
        path: 'atrakcje/napis-milosc/:city',
        element: <NapisMiloscPage />,
      },
      {
        path: 'atrakcje/neonowy-napis-na-wesele',
        element: <CanonicalNeonLightsPage />,
      },
      {
        path: 'atrakcje/neonowy-napis-na-wesele/:city',
        element: <NeonLightsPage />,
      },
      {
        path: 'atrakcje/dekoracja-swiatlem',
        element: <CanonicalLightDecorPage />,
      },
      {
        path: 'atrakcje/dekoracja-swiatlem',
        element: <CanonicalLightDecorPage />,
      },
      {
        path: 'atrakcje/dekoracja-swiatlem/:city',
        element: <LightDecorPage />,
      },
      {
        path: 'atrakcje/kasyno',
        element: <CanonicalHotCasinoPage />,
      },
      {
        path: 'atrakcje/kasyno/:city',
        element: <HotCasinoPage />,
      },
      {
        path: 'reviews',
        element: <ReviewsPage />,
      },
      {
        path: 'kontakt',
        element: <ContactPage />,
      },
      {
        path: 'polityka-prywatnosci',
        element: <PrivatePolicy />,
      },
      {
        path: 'zasieg-dzialania',
        element: <RangeOfActivity />,
      },
    ],
  },
  {
    path: '/admin',
    errorElement: <ErrorBoundary />,
    element: <AdminLayout />,
    children: [
      { path: '', element: <AdminPage /> },
      { path: 'auth/login', element: <LoginPage /> },
      { path: 'auth/signup', element: <SigninPage /> },
    ],
  },
]);
