/* eslint-disable react/no-children-prop */
import { useAppDispatch } from '@store/hooks';
import { snackbarAction } from '@store/slices/snackbarReducer';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';

import { ConnectOption } from '../../../types/types';
import { Portal } from '../Portal/Portal';

import classes from './snackbar.module.scss';

export type SnackbarProps = {
  message: string;
  status: ConnectOption;
  timeout?: number;
};

export const Snackbar = ({
  message,
  status,
  timeout = 3000,
}: SnackbarProps) => {
  const [showSnackbar, setSnackbar] = useState(false);
  const style = classNames(classes.snackbar, classes[status]);
  const disoatch = useAppDispatch();

  const containerVariants = {
    hidden: {
      opacity: 1,
      x: '-100vw',
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        type: 'spring',
        mass: 0.4,
        damping: 8,
        when: 'beforeChildren',
        staggerChildren: 0.4,
      },
    },
  };

  useEffect(() => {
    if (status) {
      setSnackbar(true);
      const timer = setTimeout(() => {
        setSnackbar(false);
        disoatch(snackbarAction({ message: '', status: '' }));
      }, timeout);
      return () => {
        return clearTimeout(timer);
      };
    }
  }, [status, timeout]);

  const snackbar = (
    <AnimatePresence>
      {showSnackbar && (
        <motion.div
          className={style}
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          exit={{ x: '-100vh' }}
        >
          <h3>{message}</h3>
        </motion.div>
      )}
    </AnimatePresence>
  );

  return <Portal children={snackbar} portalType="snackbar" />;
};
