import React, { FC } from 'react';

import { useNavigate } from 'react-router-dom';

import { Divider, LazyImage, LinkComponent, Typography } from '..';

import classes from './style.module.scss';

export interface RedirectItemProps {
  image: string;
  title: string;
  describe: string;
  to: string;
  divider?: boolean;
}

export const RedirectItem: FC<RedirectItemProps> = ({
  describe,
  image,
  title,
  to,
  divider = false,
}) => {
  const navigate = useNavigate();
  const redirectHandler = () => {
    return navigate(to);
  };
  return (
    <div className={classes.item}>
      <LazyImage
        src={image}
        className={classes.image}
        height="200px"
        onClick={redirectHandler}
      />
      <Typography
        size="small"
        component="h3"
        fontFamily="barlow-italic-bold"
        color="--main-gold"
        letterSpacing={1.5}
        mt={2}
      >
        {title}
      </Typography>
      <Typography
        textAlign="center"
        size="x-small"
        component="p"
        fontFamily="barlow-italic"
        color="--white-shadow"
        p={2}
      >
        {describe}
      </Typography>
      <LinkComponent
        to={to}
        color="--main-gold"
        fontFamily="barlow-italic-bold"
        className={classes.link}
        label={title}
      >
        więcej
      </LinkComponent>
      {divider && (
        <div style={{ width: '100%', marginTop: '16px' }}>
          <Divider variant="middle" />
        </div>
      )}
    </div>
  );
};
