import { Box } from '@components/UI';
import { useMediaQuery, useMobile } from '@hooks/index';
import { ImageList, ImageListItem, Stack } from '@mui/material';
import { ImageGalleryItem } from '@pages/home/gallery/images/images-import';
import React, { FC } from 'react';

import classes from './style.module.scss';

interface ImageGalleryProps {
  images: ImageGalleryItem[];
}

export const ImageGallery: FC<ImageGalleryProps> = ({ images }) => {
  const isMobile = useMobile();
  const lg = useMediaQuery('lg');
  const md = useMediaQuery('md');
  const xl = useMediaQuery('xl');

  const cols = isMobile ? 1 : md ? 2 : lg ? 3 : xl ? 4 : 5;

  const getRandomImages = (images: ImageGalleryItem[], num: number) => {
    const result = new Set();
    while (result.size < num) {
      const randomIndex = Math.floor(Math.random() * images.length);
      result.add(images[randomIndex]);
    }
    return Array.from(result) as ImageGalleryItem[];
  };
  const shortList = isMobile
    ? getRandomImages(images, 10)
    : getRandomImages(images, images.length);

  const imagesList = shortList.map((image) => {
    return <ImageItem key={image.src} name={image.name} src={image.src} />;
  });
  return (
    <Stack spacing={4} margin="0 auto">
      <Box sx={{ width: '100%', height: 'auto' }}>
        <ImageList variant="masonry" cols={cols} gap={8}>
          {imagesList}
        </ImageList>
      </Box>
    </Stack>
  );
};

const ImageItem: FC<ImageGalleryItem> = ({ src, name }) => {
  return (
    <ImageListItem>
      <img
        src={src}
        alt={name}
        loading="lazy"
        width={200}
        className={classes.image}
      />
    </ImageListItem>
  );
};
