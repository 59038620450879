import { SnackbarProps } from '@components/UI';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { SliceName } from '../enums';

const initialState: SnackbarProps = {
  message: '',
  status: '',
  timeout: 4000,
};

const snackbarSlice = createSlice({
  name: SliceName.SNACKBAR,
  initialState,
  reducers: {
    snackbarAction: (state, action: PayloadAction<SnackbarProps>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
      state.timeout = action.payload.timeout;
    },
  },
});

export const { snackbarAction } = snackbarSlice.actions;

export const SnackbarActions = snackbarSlice.actions;

export default snackbarSlice.reducer;
