import clsx from 'clsx';
import React, { FC } from 'react';

import classes from './style.module.scss';
import { CustomTypographyProps } from './types';
import { TypographyStyled } from './Typography.styles';

export const Typography: FC<CustomTypographyProps> = ({
  children,
  uppercase = false,
  underline = false,
  textAlign = 'left',
  color = '--black',
  size = 'medium',
  variant = 'text',
  fontFamily,
  component = 'p',
  className = '',
  ...props
}) => {
  return (
    <TypographyStyled
      {...props}
      sx={{ whiteSpace: component === 'pre' ? 'pre-wrap' : 'initial' }}
      textAlign={textAlign}
      color={color}
      size={size}
      component={component}
      fontFamily={fontFamily}
      uppercase={uppercase}
      underline={underline}
      className={clsx(
        classes.typography,
        className,
        classes[textAlign],
        classes[variant as keyof typeof classes],
        classes[color as keyof typeof classes],
        classes[`${variant}--${fontFamily}`],
        { [classes.uppercase]: uppercase }
      )}
    >
      {children}
    </TypographyStyled>
  );
};
