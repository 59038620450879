import Looks3Icon from '@mui/icons-material/Looks3';
import Looks4Icon from '@mui/icons-material/Looks4';
import Looks5Icon from '@mui/icons-material/Looks5';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';

export const timelineElements = [
  {
    id: 1,
    title: 'Zbieranie Informacji od Klienta',
    description:
      'a początku wszystko zaczyna się od rozmowy. Chcemy poznać Twoje potrzeby, oczekiwania oraz wizję wydarzenia. To etap, gdzie słuchamy i zadajemy pytania, aby lepiej zrozumieć, co jest dla Ciebie najważniejsze.',
    date: 'Krok pierwszy',
    icon: LooksOneIcon,
  },
  {
    id: 2,
    title: 'Indywidualne Projektowanie Eventu',
    description:
      'Każdy event jest jak unikalna opowieść. Na tej podstawie tworzymy „szyte na miarę” rozwiązania, które idealnie pasują do Twojego pomysłu i celów wydarzenia. Nasz zespół kreatywny projektuje scenariusz, który odzwierciedla charakter i styl Twojej firmy lub osobiste preferencje.',
    date: 'Krok drugi',
    icon: LooksTwoIcon,
  },
  {
    id: 3,
    title: 'Ustalanie Szczegółów',
    description:
      'W tym kroku omawiamy wszystkie elementy: od scenografii, przez atrakcje, po kwestie techniczne. Zapewniamy, że każdy detal jest dokładnie zaplanowany i zgodny z Twoimi oczekiwaniami. To również moment, w którym finalizujemy harmonogram i logistykę wydarzenia.',
    date: 'Krok czwarty',
    icon: Looks3Icon,
  },
  {
    id: 4,
    title: 'Realizacja',
    description:
      'Dzień eventu to czas, gdy nasz zespół wkracza do akcji. Zapewniamy sprawną realizację zaplanowanego scenariusza, dbając o to, aby wszystko przebiegło płynnie i bezproblemowo. Nasz zespół jest zawsze na miejscu, aby zagwarantować, że wszystkie elementy eventu współgrają ze sobą idealnie.',
    date: 'Krok czwarty',
    icon: Looks4Icon,
  },
  {
    id: 5,
    title: 'Zadowolenie Klienta',
    description:
      'Po zakończeniu eventu naszym celem jest Twoje pełne zadowolenie. Skupiamy się na zbieraniu opinii i uwag, które są dla nas cennym źródłem informacji. Nasz sukces mierzymy poprzez Twoją satysfakcję i radość uczestników wydarzenia.',
    date: 'Krok piąty',
    icon: Looks5Icon,
  },
];
