import { useAppDispatch } from '@store/hooks';
import { snackbarAction } from '@store/slices/snackbarReducer';
import { useEffect, useState } from 'react';
import { AreaProps } from 'src/types/event-area-types';

export const useGetArea = (url: string) => {
  const [area, setArea] = useState<AreaProps[]>([
    {
      name: 'Województwo Warmińsko-Mazurskie',
      citis: [
        {
          name: {
            nominativus: 'Olsztyn',
            locativus: 'Olsztynie',
            genetivus: 'Olsztyna',
          },
          path: 'olsztyn',
          addressLocality: '10-057',
          selectedArea: true,
        },
      ],
    },
  ]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setArea(data);
      } catch (error) {
        dispatch(
          snackbarAction({
            message: `Problem z pobraniem miejscowości. Error: ${error}`,
            status: 'error',
          })
        );
      }
    };

    fetchData();
  }, []);

  return area;
};
