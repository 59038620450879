import { ValidatorType, validate } from '@utils/validators/artists-validators';

export interface InitialStateProps {
  val: string | File | null;
  isValid: boolean;
  isTouched: boolean;
}
export type ActionReducerProps =
  | { type: 'CHANGE'; val: string | File | null; validators: ValidatorType[] }
  | { type: 'SELECT'; val: string | File | null }
  | { type: 'TOUCH' };

export const inputReducer = (
  state: InitialStateProps,
  action: ActionReducerProps
) => {
  switch (action.type) {
    case 'CHANGE': {
      let valueToValidate = '';
      if (typeof action.val === 'string' || action.val !== null) {
        valueToValidate = action.val.toString();
      }
      return {
        ...state,
        val: action.val,
        isValid: validate(valueToValidate, action.validators),
      };
    }
    case 'SELECT': {
      return {
        ...state,
        val: action.val,
      };
    }
    case 'TOUCH':
      return {
        ...state,
        isTouched: true,
      };
    default:
      return state;
  }
};
