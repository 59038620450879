import clsx from 'clsx';
import React, { CSSProperties, FC } from 'react';

import classes from './style.module.scss';

export interface VimeoVideoProps {
  videoId: string;
  width?: number | string;
  height?: CSSProperties['height'];
  fullWidth?: boolean;
}

const VimeoVideo: FC<VimeoVideoProps> = ({
  videoId,
  fullWidth = false,
  height = '600px',
}) => {
  const src = `https://player.vimeo.com/video/${videoId}`;
  return (
    <div
      className={clsx(classes.container, { [classes.fullwidth]: fullWidth })}
      style={{ height }}
    >
      <iframe
        src={src}
        allow="autoplay; fullscreen; picture-in-picture"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
        title="Event Rulers"
      ></iframe>
    </div>
  );
};

export default VimeoVideo;
