/* eslint-disable @typescript-eslint/no-explicit-any */
import { Divider, DividerProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledDivider: any = styled(Divider)<DividerProps>((props) => {
  return {
    listStyle: 'none',
    ...props,
  };
});
