import { useState, useCallback, useRef, useEffect } from 'react';

type RequestHeaders = HeadersInit;
type RequestMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';

interface ApiResponse<T> {
  data?: T;
  message?: string;
  status?: number;
}

export const useHttpClient = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const activeHttpRequests = useRef<AbortController[]>([]);

  const sendRequest = useCallback(
    async <T, TBody = BodyInit | null>(
      url: string,
      method: RequestMethod = 'GET',
      body?: TBody, // Zmień domyślną wartość na opcjonalny parametr
      headers: RequestHeaders = {}
    ): Promise<ApiResponse<T>> => {
      setIsLoading(true);
      const httpAbortCtrl = new AbortController();
      activeHttpRequests.current.push(httpAbortCtrl);

      try {
        const response = await fetch(url, {
          method,
          body: body
            ? body instanceof FormData
              ? body
              : JSON.stringify(body)
            : null,
          headers:
            body instanceof FormData
              ? headers
              : { ...headers, 'Content-Type': 'application/json' },
          signal: httpAbortCtrl.signal,
        });

        const responseData = await response.json();

        activeHttpRequests.current = activeHttpRequests.current.filter(
          (reqCtrl) => {
            return reqCtrl !== httpAbortCtrl;
          }
        );

        if (!response.ok) {
          throw new Error(responseData.message || 'Request failed!');
        }

        setIsLoading(false);
        return responseData;
      } catch (err) {
        setError((err as Error).message || 'Something went wrong!');
        setIsLoading(false);
        throw err;
      }
    },
    []
  );

  const clearError = () => {
    setError(null);
  };

  useEffect(() => {
    return () => {
      activeHttpRequests.current.forEach((abortCtrl) => {
        return abortCtrl.abort();
      });
    };
  }, []);

  return { isLoading, error, sendRequest, clearError };
};
