import { Box } from '@components/UI';
import { BoxProps } from '@mui/material';
import React, { FC, ElementType, useEffect, useState } from 'react';
import styled, { keyframes, css } from 'styled-components';

import * as SvgDecorators from './decorators.imports';
import { DecoratorVariant } from './decorators.type';

interface DecoratorSVGProps {
  svgComponent?: ElementType;
  variant?: DecoratorVariant;
  backgroundOpacity?: number;
  rotate?: number;
  reflection?: boolean;
  color?: string;
  duration?: number;
  delay?: number;
  height?: BoxProps['height'];
  width?: BoxProps['width'];
  p?: BoxProps['p'];
  m?: BoxProps['m'];
  margin?: BoxProps['margin'];
  sx?: BoxProps['sx'];
}

export const DecoratorSVG: FC<DecoratorSVGProps> = ({
  variant = 'separator_1',
  width,
  m,
  p,
  sx,
  margin,
  svgComponent,
  ...props
}) => {
  const SvgComponent = SvgDecorators[variant];

  const showSvgComponent = svgComponent ? svgComponent : SvgComponent;

  if (!SvgComponent) {
    console.warn(`Nie znaleziono dekoratora dla wariantu: ${variant}`);
    return null;
  }

  return (
    <Box display="flex" p={p} m={m} width={width} sx={sx} margin={margin}>
      <OrnamentShape
        variant="decorator_18"
        {...props}
        svgComponent={showSvgComponent}
      />
    </Box>
  );
};

const OrnamentShape: React.FC<DecoratorSVGProps> = ({
  color = '#ccc',
  duration = 10,
  delay = 2,
  height = 150,
  svgComponent,
  backgroundOpacity,
  rotate,
  reflection,
}) => {
  const [pathLength, setPathLength] = useState<number>(0);

  useEffect(() => {
    const path = document.getElementById(
      'ornamentPath'
    ) as unknown as SVGPathElement;
    if (path) {
      const length = path.getTotalLength();
      setPathLength(length / 2);
    }
  }, []);

  return (
    <Line
      as={svgComponent}
      pathLength={pathLength}
      color={`var(${color})`}
      duration={duration}
      delay={delay}
      height={height}
      backgroundOpacity={backgroundOpacity}
      rotate={rotate}
      reflection={reflection}
    />
  );
};

interface LineProps {
  pathLength: number;
  color: string;
  duration?: number;
  delay?: number;
  height?: BoxProps['height'];
  strokeWidth?: number;
  backgroundOpacity?: number;
  rotate?: number;
  reflection?: boolean;
}

const ornamentPath = (length: number, color: string, backgroundOpacity = 0) => {
  return keyframes`
    0% {
      fill-opacity: 0;
      stroke-opacity: 1;
      fill: transparent;
      stroke-dashoffset: ${length}px;
    }
    80% {
      stroke-opacity: 1;
      fill: ${color};
      fill-opacity: ${backgroundOpacity * 0.5};
      stroke-dashoffset: 0;
    }
    100% {
      stroke-opacity: 1;
      fill: ${color};
      fill-opacity: ${backgroundOpacity * 0.7};
      stroke-dashoffset: 0;
    }
  `;
};

const Line = styled.svg<LineProps>`
  transform: rotate(
    ${(props) => {
      return props.rotate;
    }}deg
  );
  transform: scaleX(
    ${(props) => {
      return props.reflection ? -1 : 'inherit';
    }}
  );
  height: ${(props) => {
    return props.color;
  }}px;
  display: block;
  margin: auto;

  & #ornamentPath {
    stroke: ${(props) => {
      return props.color;
    }};
    fill: ${(props) => {
      return props.color;
    }};
    stroke-dasharray: ${(props) => {
      return props.pathLength;
    }}px;
    stroke-dashoffset: ${(props) => {
      return props.pathLength;
    }}px;
    stroke-opacity: 0;
    fill-opacity: 0;
    animation: ${(props) => {
      return css`
        ${ornamentPath(props.pathLength, props.color, props.backgroundOpacity)}
        ${props.duration}s 
        linear
        ${props.delay}s
        backwards
      `;
    }};
    animation-fill-mode: forwards;
  }
`;

export default DecoratorSVG;
