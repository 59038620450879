export interface SlidesProps {
  name: string;
  subTitle?: string;
  img: string;
  path?: string;
}

export const extrasRedirect: SlidesProps[] = [
  {
    name: 'Fotolustro',
    path: '/atrakcje/fotobudka',
    img: `/img/page/extras/fotolustro-1-desktop.jpg`,
    subTitle: 'Wyjątkowe wspomnienia, w wyjątkowym stylu',
  },
  {
    name: 'Taniec w chmurach',
    path: '/atrakcje/ciezki-dym',
    img: `/img/page/extras/low-fog/low-fog-dunaj-low.jpeg`,
    subTitle: 'Zjawiskowy efekt',
  },
  {
    name: 'Fontanny iskier',
    path: '/atrakcje/fontanny-iskier',
    img: `/img/page/extras/iskry-kuc.jpg`,
    subTitle: 'Efekt WOW, który zaskoczy',
  },
  {
    name: 'Napis Love',
    path: '/atrakcje/napis-love',
    img: `/img/page/extras/napis-love.jpg`,
    subTitle: 'Love - napis, który mówi wszystko',
  },
  {
    name: 'Napis Miłość',
    path: '/atrakcje/napis-milosc',
    img: `/img/page/extras/milosc/background-img-mobile.jpg`,
    subTitle: 'Miłość świeci najjaśniej',
  },
  {
    name: 'Miny Dymne',
    path: '/atrakcje/kolorowy-dym-na-wesele',
    img: `/img/page/extras/miny-dymne.jpg`,
    subTitle: 'Wyjątkowy efekt',
  },
  {
    name: 'Neonowy napis',
    path: '/atrakcje/neonowy-napis-na-wesele',
    img: `/img/page/extras/neon/neon-2.jpg`,
    subTitle: 'Gustownie i elegancko',
  },
  {
    name: 'Dekoracja Światłem',
    path: '/atrakcje/dekoracja-swiatlem',
    img: `/img/page/extras/decor/decor-3.jpg`,
    subTitle: 'Magia światła tworząca atmosferę',
  },
];
