import useLocalStorage from '@hooks/useLocalStorage';
import { router } from '@router/Router';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { aggregateRatingFunc } from '@store/slices/schemaReducer';
import { userAction } from '@store/slices/userReducer';
import React, { Suspense, useEffect, useState } from 'react';

import './app.module.scss';

import { Helmet } from 'react-helmet';
import { RouterProvider } from 'react-router-dom';

import { Loader } from './components';

import { useFetchData } from './hooks';
import { UserProps } from './types/user';

function App() {
  const dispatch = useAppDispatch();
  const url = 'https://rulersgroup.pl/server/api/reviews/rating';

  const initialSchema = useAppSelector((state) => {
    return state.schema;
  });

  const [description, setDescription] = useState(initialSchema.description);
  const [cannonicPage, setCannonicPage] = useState(
    initialSchema.cannonicalPage
  );

  const { data } = useFetchData(url) as unknown as {
    data: {
      rating: string;
      user_ratings_total: string;
    };
  };

  if (data) {
    dispatch(
      aggregateRatingFunc({
        ratingValue: data.rating,
        reviewCount: data.user_ratings_total,
      })
    );
  }

  const [user] = useLocalStorage<Omit<
    UserProps & { expiration: string },
    'user_password'
  > | null>('user', null);

  useEffect(() => {
    if (user?.user_token && new Date(user.expiration) > new Date()) {
      dispatch(userAction(user));
    }
  }, [user]);

  useEffect(() => {
    if (initialSchema.description) {
      setDescription(initialSchema.description);
    }
    if (initialSchema.cannonicalPage) {
      setCannonicPage(initialSchema.cannonicalPage);
    }
  }, [initialSchema.description, initialSchema.cannonicalPage]);

  return (
    <>
      <div key={initialSchema.title}>
        <Helmet>
          <title>{initialSchema.title}</title>
          <link rel="preload" as="image" href={initialSchema.preloadImg} />
          <link
            rel="canonical"
            href={cannonicPage ? cannonicPage : 'https://eventrulers.pl'}
          />
          <meta property="og:title" content={initialSchema.title} />
          <meta name="description" content={description} />
          <meta property="og:description" content={description} />
          <script type="application/ld+json">
            {JSON.stringify(initialSchema.schmaOrg)}
          </script>
        </Helmet>
      </div>
      <Suspense fallback={<Loader />}>
        <RouterProvider router={router} />
      </Suspense>
    </>
  );
}

export default App;
