// import { Link } from '@components/UI/Link/Link';
import { TicTokIcon } from '@assets/Custom/TikTok';
import { LazyImage } from '@components/UI/Image/Image';
import { LinkComponent } from '@components/UI/Link/Link';
import { fontSizeMap } from '@components/UI/styled';
import { CustomColors, SizesType } from '@components/UI/types';
import AddIcon from '@mui/icons-material/Add';
import AddAlertIcon from '@mui/icons-material/AddAlert';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import CallIcon from '@mui/icons-material/Call';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';

import {
  IconButton as IconButtonMUI,
  SvgIconProps,
  IconButtonProps as IconButtonPropsMUI,
} from '@mui/material';

import clsx from 'clsx';
import { MouseEventHandler } from 'react';
import { LinkProps } from 'react-router-dom';

import { CustomButtonVarians, ButtonProps } from '../Button.types';

import classes from './icon-button.module.scss';

export interface MySvgIconProps extends SvgIconProps {
  style?: React.CSSProperties;
  margin?: React.CSSProperties['margin'];
}

type TargetType = '_blank' | '_self' | '_parent' | '_top';

const buttonWariant = (
  variant: CustomButtonVarians,
  props: MySvgIconProps,
  size: SizesType
) => {
  const color = props ? props.color : 'inherit';
  const margin = props ? props.margin : 'inherit';

  const customStyle =
    color && color.startsWith('--')
      ? { color: `var(${color})`, margin }
      : { margin };

  let iconProps = {
    ...props,
    style: { ...customStyle, ...props.style }, // scalenie stylów
  };
  const fontSize = fontSizeMap[size];
  if (fontSize) {
    iconProps = {
      ...iconProps,
      style: { fontSize, ...iconProps.style },
    };
  }

  switch (variant) {
    case 'add':
      return <AddIcon {...iconProps} />;
    case 'alert':
      return <AddAlertIcon {...iconProps} />;
    case 'arrow-down':
      return <ArrowDropDownIcon {...iconProps} />;
    case 'arrow-up':
      return <ArrowDropUpIcon {...iconProps} />;
    case 'arrow-left':
      return <ArrowLeftIcon {...iconProps} />;
    case 'arrow-right':
      return <ArrowRightIcon {...iconProps} />;
    case 'phone':
      return (
        <CallIcon
          {...iconProps}
          sx={{ minHeight: '48px', minWidth: '48px', mr: 2 }}
        />
      );
    case 'check':
      return <CheckCircleIcon {...iconProps} />;
    case 'wesele-z-klasa':
      return (
        <LazyImage
          {...iconProps}
          style={iconProps.style}
          src="/img/wesele-z-klasa.png"
          alt="Event Rulers Więcej niż Wodzireje"
        />
      );
    case 'close':
      return <CloseIcon {...iconProps} />;
    case 'delete':
      return <DeleteIcon {...iconProps} />;
    case 'email':
      return (
        <EmailIcon
          {...iconProps}
          sx={{ minHeight: '48px', minWidth: '48px', mr: 2 }}
        />
      );
    case 'instagram':
      return <InstagramIcon {...iconProps} />;
    case 'facebook':
      return <FacebookIcon {...iconProps} />;
    case 'print':
      return <LocalPrintshopIcon {...iconProps} />;
    case 'thumb':
      return <ThumbUpIcon {...iconProps} />;
    case 'tik-tok':
      return <TicTokIcon {...iconProps} />;
    case 'keyboard-double-arrow':
      return <KeyboardDoubleArrowDownIcon {...iconProps} />;
    default:
      return <DoneIcon {...iconProps} />;
  }
};

interface IconButtonProps {
  variant: CustomButtonVarians;
  iconProps?: SvgIconProps;
  to?: LinkProps['to'];
  target?: TargetType;
  size?: SizesType;
  color?: SvgIconProps['color'] | CustomColors;
  className?: IconButtonPropsMUI['className'];
  onClick?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
  mb?: number | string;
  mt?: number | string;
  mr?: number | string;
  ml?: number | string;
  m?: number | string;
  style?: ButtonProps['style'];
}

export const IconButton = ({
  variant,
  iconProps,
  to,
  target = '_self',
  size = 'medium',
  color = 'inherit',
  className,
  mb = 0,
  mt = 0,
  mr = 0,
  ml = 0,
  m = 0,
  style,
  onClick,
}: IconButtonProps) => {
  let computedIconProps = iconProps;
  const marginBottom = typeof mb === 'string' ? mb : `${mb * 8}px`;
  const marginTop = typeof mt === 'string' ? mt : `${mt * 8}px`;
  const marginRight = typeof mr === 'string' ? mr : `${mr * 8}px`;
  const marginLeft = typeof ml === 'string' ? ml : `${ml * 8}px`;
  const margin = typeof m === 'string' ? m : `${m * 8}px`;

  const iconButtonStyle = {
    marginBottom,
    marginTop,
    marginRight,
    marginLeft,
    margin,
    ...style,
  };

  const computedProps = target &&
    target === '_blank' && { target, rel: 'noopener noreferrer' };

  if (color) {
    computedIconProps = { ...iconProps, color };
  }

  const icon = buttonWariant(variant, computedIconProps ?? {}, size);
  return (
    <>
      {to ? (
        <LinkComponent
          label={`icon-${variant}`}
          {...computedProps}
          to={to}
          style={iconButtonStyle}
        >
          {icon}
        </LinkComponent>
      ) : (
        <IconButtonMUI
          onClick={onClick}
          className={clsx(classes.icon, className)}
          style={iconButtonStyle}
        >
          {icon}
        </IconButtonMUI>
      )}
    </>
  );
};
