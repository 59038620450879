import { CustomColors } from '@components/UI/types';
import clsx from 'clsx';
import { motion, MotionProps } from 'framer-motion';
import React, { FC } from 'react';

import classes from '../button.module.scss';
import { ButtonProps } from '../Button.types';

// const buttonVariants = {
//   hover: {
//     scale: 1.1,
//     textShadow: '0px 0px 8px rgba(255,255,255,0.8)',
//     boxShadow: '0px 0px 8px rgba(255,255,255,0.7)',
//     transition: {
//       repeat: Infinity,
//       repeatType: 'yoyo' as 'loop' | 'mirror' | 'reverse',
//     },
//   },
// };

// const containerVariants = {
//   hidden: {
//     marginBottom: 0,
//     scale: 0,
//   },
//   visable: {
//     marginBottom: 20,
//     scale: 2.5,
//     transition: {
//       yoyo: Infinity,
//       type: 'spring',
//       stiffness: 1200,
//       delay: 1.5,
//     },
//   },
// };

interface MotionButtonProps extends MotionProps {
  className?: ButtonProps['className'];
  fullWidth?: ButtonProps['fullWidth'];
  disabled?: ButtonProps['disabled'];
  onClick?: ButtonProps['onClick'];
  color?: CustomColors;
  ariaLabel: string;
}

export const MotionButton: FC<MotionButtonProps> = ({
  className,
  fullWidth,
  disabled,
  children,
  variants,
  onClick,
  color,
  ariaLabel = 'wciśnij przycisk',
  ...props
}) => {
  return (
    <motion.button
      variants={variants}
      initial="hidden"
      animate="visable"
      style={{ color }}
      disabled={disabled}
      aria-label={ariaLabel}
      onClick={onClick}
      className={clsx(classes['button-motion'], className, {
        [classes['full-width']]: fullWidth,
        [classes['disabled-on']]: disabled,
      })}
      {...props}
    >
      {children}
    </motion.button>
  );
};
