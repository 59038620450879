import React, { FC } from 'react';
import {
  VerticalTimeline,
  VerticalTimelineElement,
  VerticalTimelineProps,
  VerticalTimelineElementProps,
} from 'react-vertical-timeline-component';

import 'react-vertical-timeline-component/style.min.css';

import { Typography } from '..';

import classes from './style.module.scss';

import { timelineElements } from './timelineElements';

export interface TimlineProps {
  timelineProps?: VerticalTimelineProps;
  elementProps?: VerticalTimelineElementProps;
}

export const Timeline: FC<TimlineProps> = ({ elementProps, timelineProps }) => {
  const workIconStyles = { background: 'var(--main-gold)' };
  return (
    <VerticalTimeline {...timelineProps}>
      {timelineElements.map((element) => {
        return (
          <VerticalTimelineElement
            {...elementProps}
            key={element.id}
            date={element.date}
            dateClassName={classes.date}
            iconStyle={workIconStyles}
            icon={<element.icon fontSize="large" />}
          >
            <Typography
              component="h3"
              color="--dark-grey"
              fontFamily="barlow-bold"
            >
              {element.title}
            </Typography>
            <Typography
              component="p"
              color="--dark-grey"
              fontFamily="barlow-italic"
              textAlign="justify"
            >
              {element.description}
            </Typography>
          </VerticalTimelineElement>
        );
      })}
    </VerticalTimeline>
  );
};
