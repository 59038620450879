import {
  DecoratorSVG,
  Divider,
  Typography,
  BoxCustomProps,
  DecoratorVariant,
  Box,
  HotCasinoDurationLogo,
} from '@components/UI';
import { useMediaQuery } from '@hooks/index';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import React, { CSSProperties, FC, memo } from 'react';

import classes from './style.module.scss';

interface PageSectionLayoutProps {
  id: string;
  title?: string;
  subtitleText?: string;
  children: BoxCustomProps['children'];
  className?: BoxCustomProps['className'];
  maxWidth?: BoxCustomProps['maxWidth'];
  padding?: CSSProperties['padding'];
  darkBackgroundColor?: boolean;
  decorator?: DecoratorVariant;
  divider?: boolean;
}

export const SectionLayout: FC<PageSectionLayoutProps> = ({
  id,
  title,
  subtitleText,
  children,
  className,
  darkBackgroundColor,
  decorator,
  maxWidth = '1200px',
  divider = false,
  padding,
}) => {
  const isTablet = useMediaQuery('md');
  const isMobile = useMediaQuery('sm');
  const backgroundColor = darkBackgroundColor
    ? 'var(--background-color-soft)'
    : 'var(--white-shadow)';
  const subTitleColor = darkBackgroundColor ? '--white-shadow' : '--dark-grey';

  const shouldRenderDiv = decorator || title || subtitleText;
  return (
    <motion.section
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className={clsx(classes.section, className)}
      id={`${id}-section`}
      style={{ backgroundColor, padding }}
    >
      {shouldRenderDiv && (
        <div className={classes.heading}>
          {title && (
            <Typography
              component="h1"
              fontFamily="lemon-jelly"
              color={darkBackgroundColor ? '--main-gold' : '--dark-gold'}
              textAlign="center"
              size={isMobile ? 'xx-large' : isTablet ? 'xxx-large' : 'title'}
            >
              {title}
            </Typography>
          )}
          <div className={classes.ornament}>
            {decorator && decorator === 'hot_casino' ? (
              <HotCasinoDurationLogo />
            ) : (
              decorator && (
                <DecoratorSVG
                  variant={decorator}
                  color={darkBackgroundColor ? '--main-gold' : '--dark-gold'}
                  height={isTablet ? '60px' : '100px'}
                  duration={4}
                  delay={1}
                  margin={{ xs: '20px auto', md: '60px auto' }}
                />
              )
            )}
          </div>
          {subtitleText && (
            <Typography
              component="h2"
              size={{
                xs: 'small',
                sm: 'medium',
                md: 'large',
                lg: 'x-large',
                xl: 'x-large',
              }}
              fontFamily="barlow-italic"
              letterSpacing={1}
              textAlign="center"
              mb={4}
              m={{ xs: 2, md: 8 }}
              color={subTitleColor}
            >
              {subtitleText}
            </Typography>
          )}
        </div>
      )}
      {divider && <Divider variant="middle" className={classes.divider} />}

      <Box
        component="article"
        maxWidth={maxWidth}
        className={classes.container}
      >
        {children}
      </Box>
    </motion.section>
  );
};

export default memo(SectionLayout);
