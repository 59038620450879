import clsx from 'clsx';
import React, { useRef, useEffect } from 'react';

import { IconButton } from '../Button/IconButton/IconButton';

import { CustomColors } from '../types';

import classes from './modal.module.scss';

import ModalWrapper from './ModalWrapper';

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  closeButton?: boolean;
  closeButtonColor?: CustomColors;
  offBackdropClose?: boolean;
  darkBackgroundColor?: boolean;
};

export const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  children,
  closeButton = false,
  offBackdropClose = false,
  darkBackgroundColor = false,
  closeButtonColor,
}) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (offBackdropClose) return;
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isOpen) {
    return null;
  }

  return (
    <ModalWrapper open={isOpen}>
      <div
        className={clsx(classes.container, {
          [classes['container--background-dark']]: darkBackgroundColor,
        })}
        ref={modalRef}
      >
        <div className={classes.button} ref={modalRef}>
          {closeButton && (
            <IconButton
              variant="close"
              onClick={onClose}
              color={closeButtonColor}
            />
          )}
        </div>
        {children}
      </div>
    </ModalWrapper>
  );
};

export default Modal;
