import { plPL } from '@mui/material/locale';
import { createTheme } from '@mui/material/styles';

import { palette } from './palette';

export const theme = createTheme(
  {
    palette,
    components: {
      MuiAppBar: {
        styleOverrides: {
          root: {
            zIndex: 1300,
            width: '100%',
            padding: '10px',
          },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            zIndex: 1300,
            width: '100%',
            height: 'auto',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: '0 auto',
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          underline: {
            '&:before': {
              borderBottomColor: palette.gold.main, // Kolor w stanie nieaktywnym
            },
            '&:after': {
              borderBottomColor: palette.gold.light, // Kolor w stanie aktywnym (focus)
            },
            '&:hover:not(.Mui-disabled):before': {
              borderBottomColor: palette.gold.main, // Kolor w stanie aktywnym (focus)
            },
            borderBottomColor: palette.gold.main,
            // Możesz również dodać style dla stanu błędu, jeśli potrzebujesz
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            input: {
              color: palette.gold.dark_contrast, // Zmiana koloru tekstu
              borderColor: palette.gold.main,
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: palette.gold.main,
              },
              '&:hover fieldset': {
                borderColor: '#b8a251', // Kolor obramowania przy hover
              },
              '&.Mui-focused fieldset': {
                borderColor: '#b8a251', // Kolor obramowania przy focus
              },
            },
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: '#282625',
            color: 'white',
            fontSize: '1em',
            padding: '4px 8px 9px 8px',
            fontFamily: 'barlow-italic',
            border: '1px solid #282625',
          },
          arrow: {
            '&::before': {
              backgroundColor: '#282625',
              color: 'white',
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          input: {
            color: palette.gold.dark_contrast,
            borderColor: palette.gold.dark,
          },
          root: {
            '& element-selector': {
              borderColor: 'green',
              color: 'green',
            },
            border: 'green',
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            border: 'green',
          },
        },
      },
    },
  },
  plPL
);
