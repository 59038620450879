/* eslint-disable prettier/prettier */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { BreadcrumbListProps, SchemaAgregateRating, SchemaAreaServed, SchemaProps, SchemaVideoObject } from 'src/types/schemaOrg';

import { SliceName } from '../enums';

const initialState: SchemaProps = {
  schmaOrg: {
    '@context': 'http://schema.org',
    '@type': 'Organization',
    '@id': 'https://eventrulers.pl/#organization',
    serviceType: 'Organizacja wydarzeń',
    name: 'Event Rulers',
    provider: {
      '@type': 'Organization',
      name: 'Event Rulers',
      url: 'https://eventrulers.pl',
      logo: {
        '@type': 'ImageObject',
        inLanguage: 'pl-PL',
        '@id': 'https://eventrulers.pl/#/schema/logo/image/',
        url: 'https://eventrulers.pl/img/LOGO.png',
        contentUrl: 'https://eventrulers.pl/img/LOGO.png',
        width: 1080,
        height: 1080,
        caption: 'Event Rulers',
      },
      image: {
        '@type': 'ImageObject',
        inLanguage: 'pl-PL',
        '@id': 'https://eventrulers.pl/#/schema/hero/image/',
        url: 'https://eventrulers.pl/img/Hero.jpeg',
        contentUrl: 'https://eventrulers.pl/img/Hero.jpeg',
        width: 1295,
        height: 1295,
        caption: 'Pierwszy Taniec',
      },
      contactPoint: {
        telephone: '+48698212279',
        contactType: 'Obsługa Klienta',
      },
      sameAs: [
        'https://www.facebook.com/eventrulers',
        'https://www.instagram.com/eventrulers',
        'https://www.linkedin.com/company/rulers-group',
      ],
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '4.7',
        bestRating: '5',
        reviewCount: '84',
      },
      brand: {
        '@type': 'Brand',
        name: 'Event Rulers',
      },
      address: [
        {
          '@type': 'PostalAddress',
          streetAddress: 'Plac Róż 7',
          addressLocality: 'Nidzica',
          postalCode: '13-100',
          addressCountry: 'PL',
        },
        {
          '@type': 'PostalAddress',
          streetAddress: 'Marcina Kasprzaka 15',
          addressLocality: 'Olsztyn',
          postalCode: '10-057',
          addressCountry: 'PL',
        },
      ],
    },
    breadcrumbList: {
      '@type': 'BreadcrumbList',
      '@id': 'https://eventrulers/#breadcrumb',
      itemListElement: [
        {
          '@type': 'ListItem',
          position: 1,
          name: 'Strona główna',
          item: 'https://eventrulers.pl',
        },
      ],
    },
    potentialAction: [
      {
        '@type': 'SearchAction',
        target: {
          '@type': 'EntryPoint',
          urlTemplate: 'https://eventrulers.pl/?s={search_term_string}',
        },
        'query-input': 'required name=search_term_string',
      },
    ],
    inLanguage: 'pl-PL',
    areaServed: [
      {
        '@type': 'Place',
        name: 'Olsztyn',
        additionalProperty: {
          '@type': 'PropertyValue',
          name: 'Typ',
          value: 'Miasto',
        },
      },
      {
        '@type': 'Place',
        name: 'Województwo Warmińsko-Mazurskie',
        additionalProperty: {
          '@type': 'PropertyValue',
          name: 'Typ',
          value: 'Województwo',
        },
      },
    ],
    hasPart: null,
    image: 'https://eventrulers.pl/img/Hero.jpeg',
    description:
      'Organizacja niezapomnianych wydarzeń w Twojej okolicy. Skorzystaj z profesjonalnej agencji eventowej! Twój event może być niepowtarzalny i pełen energii. Zadbaj o najlepszą oprawę muzyczną z topową ekipą w branży',
  },
  title: 'Event Rulers - Więcej niż Wodzireje!',
  description:
    'Organizacja niezapomnianych wydarzeń w Twojej okolicy. Skorzystaj z profesjonalnej agencji eventowej! Twój event może być niepowtarzalny i pełen energii. Zadbaj o najlepszą oprawę muzyczną z topową ekipą w branży.',
  preloadImg: 'https://eventrulers.pl/img/Hero.jpeg',
  cannonicalPage: 'https://eventrulers.pl',
};

const schemaSlice = createSlice({
  name: SliceName.SCHEMA,
  initialState,
  reducers: {
    aggregateRatingFunc: (
      state,
      action: PayloadAction<Omit<SchemaAgregateRating, '@type'>>
    ) => {
      state.schmaOrg.provider.aggregateRating.ratingValue =
        action.payload.ratingValue;
      state.schmaOrg.provider.aggregateRating.reviewCount =
        action.payload.reviewCount;
    },
    updateHeadFunc: (
      state,
      action: PayloadAction<{
        serviceType: string;
        description: string;
        image: string;
        areaServed: SchemaAreaServed[];
        title: string;
        preloadImg: string;
        cannonicalPage?: string;
        breadcrumbList: BreadcrumbListProps;
      }>
    ) => {
      state.description = action.payload.description;
      state.schmaOrg.description = action.payload.description;
      state.schmaOrg.provider.name = action.payload.serviceType;
      state.schmaOrg.image = action.payload.image;
      state.schmaOrg.areaServed = action.payload.areaServed;
      state.schmaOrg.provider.name = action.payload.title;
      state.schmaOrg.breadcrumbList = action.payload.breadcrumbList;
      state.title = action.payload.title;
      state.preloadImg = action.payload.preloadImg;
      state.cannonicalPage = action.payload.cannonicalPage;
    },
    updateVideoFunc: (state, action: PayloadAction<SchemaVideoObject>) => {
      state.schmaOrg.hasPart = action.payload;
    },
  },
});

export const { aggregateRatingFunc, updateHeadFunc, updateVideoFunc } = schemaSlice.actions;

export const SchemaActions = schemaSlice.actions;

export default schemaSlice.reducer;