import { Box, Typography } from '@components/index';
import Navbar from '@components/navbar/Navbar';
import Logo from '@components/UI/Decorators/Event-Rulers/Logo';
import { useLandscape } from '@hooks/useLandscape';
import { useMobile } from '@hooks/useMobile';
import React, { useEffect, useState } from 'react';
import {
  isRouteErrorResponse,
  useNavigate,
  useRouteError,
} from 'react-router-dom';

import classes from './style.module.scss';

export const ErrorBoundary = () => {
  const error = useRouteError() as Error;
  const navigate = useNavigate();
  const isMobile = useMobile();
  const isLandscape = useLandscape();

  const [timeLeft, setTimeLeft] = useState(10);

  useEffect(() => {
    // Jeśli czas się skończył, nie robimy nic
    if (timeLeft === 0) return navigate('/');

    // Ustawienie interwału, który zmniejsza czas co sekundę
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // Czyszczenie interwału, gdy komponent zostanie odmontowany
    // lub czas się skończy
    return () => {
      return clearInterval(intervalId);
    };
  }, [timeLeft]);

  return (
    <Box>
      <Navbar />
      <article className={classes.login}>
        <div className={classes.background} role="img" />
        <div className={classes['login-wrapper']}>
          <div className={classes['login__logo']}>
            <Logo
              onClick={() => {
                return navigate('/');
              }}
              duration={0.5}
              delay={0.2}
              height={isMobile ? (isLandscape ? 80 : 160) : 300}
              width={300}
              color="--main-gold"
              aria-label="logo"
            />
          </div>

          <Typography
            fontFamily="barlow-italic-bold"
            color="--white-shadow"
            component="h1"
            size="large"
            my={4}
          >
            Za chwilę zostaniesz przekierowany na stronę główną
          </Typography>
          <Typography
            color="--white-shadow"
            component="p"
            size="medium"
            fontFamily="barlow-italic"
          >
            {isRouteErrorResponse(error)
              ? `${error.status} ${error.statusText}`
              : `${error.message}` || 'Wystąpił nieoczekiwany problem.'}
          </Typography>
          <Typography
            color="--white-shadow"
            component="p"
            size="medium"
            fontFamily="barlow-italic"
          >
            {timeLeft} s
          </Typography>
        </div>
      </article>
    </Box>
  );
};
