import { useAppDispatch } from '@store/hooks';
import { snackbarAction } from '@store/slices/snackbarReducer';
import axios, { AxiosError } from 'axios';
import { useState, useEffect } from 'react';

type UseFetchDataReturnType<T> = {
  data: T | null;
  loading: boolean;
  error: AxiosError | null;
};

export const useFetchData = <T,>(url: string): UseFetchDataReturnType<T> => {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<AxiosError | null>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    // Tworzenie źródła anulowania do zarządzania pamięcią
    const cancelTokenSource = axios.CancelToken.source();

    const fetchData = async () => {
      try {
        // Wysyłanie żądania
        const response = await axios.get<T>(url, {
          cancelToken: cancelTokenSource.token,
        });
        // Ustawianie danych z odpowiedzi
        setData(response.data);
      } catch (err) {
        // Obsługa błędów żądania
        if (axios.isCancel(err)) {
          dispatch(
            snackbarAction({
              status: 'error',
              message: `Żądanie zostało anulowane ${err}, endpoint: ${url}`,
              timeout: 5000,
            })
          );
        } else {
          setError(err as AxiosError);
        }
      } finally {
        // Zawsze wyłącz ładowanie na koniec żądania
        setLoading(false);
      }
    };

    fetchData();

    // Czyszczenie zasobów
    return () => {
      cancelTokenSource.cancel();
    };
  }, [url]);

  return { data, loading, error };
};

export default useFetchData;
