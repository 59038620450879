/* eslint-disable no-unused-vars */
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import {
  Box,
  CheckboxProps,
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox as MuiCheckbox,
} from '@mui/material';
import { TypographyProps } from '@mui/material/Typography/Typography';
import React, {
  ChangeEvent,
  forwardRef,
  memo,
  useImperativeHandle,
  useState,
} from 'react';

import { fontSizeMap } from '..';
import { CustomColors, FontFamilies, SizesType } from '../types';

import classes from './style.module.scss';

interface CustomCheckboxProps extends CheckboxProps {
  label?: string;
  variant?: 'radio' | 'checkbox' | 'circle';
  onSelectionChange?: (selected: boolean) => void;
}

export interface CheckboxHandles {
  reset: () => void;
}

export const Checkbox = memo(
  forwardRef<CheckboxHandles, CustomCheckboxProps>(
    // eslint-disable-next-line react/prop-types
    ({ label, variant = 'checkbox', onSelectionChange, ...props }, ref) => {
      const [acceptTnC, setAcceptTnC] = useState<boolean>(false);
      let iconVariant;

      switch (variant) {
        case 'radio':
          iconVariant = {
            icon: <RadioButtonUncheckedIcon />,
            checkedIcon: <RadioButtonCheckedIcon />,
          };
          break;
        case 'circle':
          iconVariant = {
            icon: <RadioButtonUncheckedIcon />,
            checkedIcon: <CheckCircleIcon />,
          };
          break;

        default:
          iconVariant = {
            icon: undefined,
            checkedIcon: undefined,
          };
          break;
      }

      useImperativeHandle(ref, () => {
        return {
          reset() {
            setAcceptTnC(false);
          },
        };
      });

      const handleChangeTnC = (event: ChangeEvent<HTMLInputElement>) => {
        const isChecked = event.target.checked as boolean;
        setAcceptTnC(isChecked);
        if (onSelectionChange) {
          onSelectionChange(isChecked);
        }
      };

      return (
        <Box className={classes.checkbox}>
          <FormControlLabel
            className={classes.label}
            label={label}
            control={
              <MuiCheckbox
                {...props}
                icon={iconVariant.icon}
                checkedIcon={iconVariant.checkedIcon}
                checked={acceptTnC}
                onChange={handleChangeTnC}
              />
            }
          />
        </Box>
      );
    }
  )
);

interface CustomMultipleCheckboxProps extends CheckboxProps {
  label?: string;
  variant?: 'radio' | 'checkbox' | 'circle';
  row?: boolean;
  multichecked?: boolean;
  labelColor?: CustomColors;
  fontFamily?: FontFamilies;
  values: Array<string | number>;
  textTransform?: TypographyProps['textTransform'];
  letterSpacing?: TypographyProps['letterSpacing'];
  labelSize?: SizesType;
}

interface MultipleCheckboxProps extends CustomMultipleCheckboxProps {
  singleSelect?: boolean;
  onSelectionChange?: (
    selected: Array<string | number> | string | number | null
  ) => void;
}

export const MultipleCheckbox = forwardRef<
  CheckboxHandles,
  MultipleCheckboxProps
>(
  (
    {
      row,
      variant,
      labelColor,
      fontFamily,
      values,
      singleSelect = false,
      size,
      labelSize,
      textTransform,
      letterSpacing,
      onSelectionChange,
    },
    ref
  ) => {
    const [selectedValues, setSelectedValues] = useState<Set<string | number>>(
      new Set()
    );

    let iconVariant: { icon: any; checkedIcon: any };

    switch (variant) {
      case 'radio':
        iconVariant = {
          icon: <RadioButtonUncheckedIcon />,
          checkedIcon: <RadioButtonCheckedIcon />,
        };
        break;
      case 'circle':
        iconVariant = {
          icon: <RadioButtonUncheckedIcon />,
          checkedIcon: <CheckCircleIcon />,
        };
        break;

      default:
        iconVariant = {
          icon: undefined,
          checkedIcon: undefined,
        };
        break;
    }

    const handleChange = (value: string | number) => {
      if (singleSelect) {
        setSelectedValues(new Set([value]));
        onSelectionChange?.(value);
      } else {
        setSelectedValues((prevSelectedValues) => {
          const newSelectedValues = new Set(prevSelectedValues);
          if (newSelectedValues.has(value)) {
            newSelectedValues.delete(value);
          } else {
            newSelectedValues.add(value);
          }
          onSelectionChange?.(Array.from(newSelectedValues));
          return newSelectedValues;
        });
      }
    };

    let valuesList: React.ReactNode[] = [];
    if (values) {
      valuesList = values.map((value) => {
        return (
          <FormControlLabel
            key={String(value)}
            sx={{
              color: `var(${labelColor})`,
              '& span': {
                letterSpacing,
                fontFamily: fontFamily,
                fontSize: `${
                  fontSizeMap[labelSize as SizesType] || fontSizeMap.medium
                }`,
              },
              textTransform: textTransform,
            }}
            label={String(value)}
            control={
              <MuiCheckbox
                size={size}
                icon={iconVariant.icon}
                checkedIcon={iconVariant.checkedIcon}
                checked={selectedValues.has(value)}
                onChange={() => {
                  return handleChange(value);
                }}
                value={value}
              />
            }
          />
        );
      });
    }

    useImperativeHandle(ref, () => {
      return {
        reset() {
          setSelectedValues(new Set());
        },
      };
    });

    return (
      <Box>
        <FormControl>
          <FormGroup row={row}>{valuesList}</FormGroup>
        </FormControl>
      </Box>
    );
  }
);
