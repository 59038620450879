import { useEffect, useState } from 'react';

function useScrollToSection(offset = 0) {
  const [isScroll, setIsScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const shouldSetScroll = window.scrollY >= offset;
      if (isScroll !== shouldSetScroll) {
        setIsScroll(shouldSetScroll);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      return window.removeEventListener('scroll', handleScroll);
    };
  }, [isScroll, offset]); // Dodaj zależności do useEffect

  return isScroll;
}

export default useScrollToSection;
