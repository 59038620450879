import { Drawer } from '@components/UI';
import { List } from '@mui/material';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { drawerFunc } from '@store/slices/globalStateReducer';
import { snackbarAction } from '@store/slices/snackbarReducer';
import { userLogout } from '@store/slices/userReducer';
import React, { memo } from 'react';

import { useLocation } from 'react-router-dom';

import { navigation } from '..';
import NavLink from '../nav-link/NavLink';
import classes from '../style.module.scss';

import MobileNavItem from './MobileNavItem/MobileNavItem';

const MobileMenu = () => {
  const toggle = useAppSelector((state) => {
    return state.globalState.menuDrawerIsOpen;
  });

  const isLogin = useAppSelector((state) => {
    return !!state.user.user_token;
  });

  const location = useLocation();
  const currentPath = location.pathname;

  const dispatch = useAppDispatch();
  const logoutHandler = () => {
    dispatch(userLogout());
    dispatch(drawerFunc(false));
    dispatch(
      snackbarAction({ message: 'Zostałeś wylogowany', status: 'edited' })
    );
    localStorage.removeItem('user');
  };

  return (
    <Drawer open={toggle}>
      <div className={classes.mobile} role="presentation">
        <List className={classes['mobile__list']}>
          {navigation.map((item) => {
            return <MobileNavItem key={item.path} item={item} />;
          })}
          {isLogin && (
            <div
              style={{
                flex: 1,
                textAlign: 'center',
                position: 'relative',
                margin: '8px',
              }}
            >
              <NavLink
                className={classes.navlink}
                onClick={logoutHandler}
                to={currentPath}
                uppercase
                isLogout
                size="x-small"
                fontFamily="barlow-italic-bold"
                color="--white-shadow"
              >
                Wyloguj
              </NavLink>
            </div>
          )}
        </List>
      </div>
    </Drawer>
  );
};

export default memo(MobileMenu);
