import { Box } from '@components/UI';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { GlobalStateActions } from '@store/slices/globalStateReducer';
import { memo } from 'react';

import classes from './style.module.scss';

const BurgerMenu = memo(() => {
  const toggle = useAppSelector((state) => {
    return state.globalState.menuDrawerIsOpen;
  });
  const dispatch = useAppDispatch();

  const handleToggleFunc = () => {
    dispatch(GlobalStateActions.drawerFunc(!toggle));
  };

  return (
    <Box
      className={classes.menu}
      onClick={handleToggleFunc}
      onKeyDown={handleToggleFunc}
      role="button"
      tabIndex={0}
      right={{ xs: '5px', sm: '15px' }}
    >
      <div className={classes.container}>
        <div
          className={`${classes.top} ${toggle ? classes['top-isActive'] : ''}`}
        />
        <div
          className={`${classes.center} ${
            toggle ? classes['center-isActive'] : ''
          }`}
        />
        <div
          className={`${classes.bottom} ${
            toggle ? classes['bottom-isActive'] : ''
          }`}
        />
        <div
          className={`${classes.text} ${
            toggle ? classes['text-isActive'] : ''
          }`}
        >
          <span className={classes['text-menu']}>MENU</span>
        </div>
      </div>
    </Box>
  );
});

BurgerMenu.displayName = 'BurgerMenu';

export default memo(BurgerMenu);
