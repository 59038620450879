import { DecoratorVariant, LazyImageProps } from '@components/UI';
import useBreadcrumbs from '@hooks/useBreadcrumbs';
import { BoxProps } from '@mui/material/Box/Box';
import { useAppDispatch } from '@store/hooks';
import { updateHeadFunc } from '@store/slices/schemaReducer';
import React, { FC, ReactNode, memo } from 'react';

import { useLocation } from 'react-router-dom';
import { CityProps } from 'src/types/event-area-types';
import { SchemaOrgIterface } from 'src/types/schemaOrg';

import { PageContent } from '../page-content/PageContent';
import { PageImage } from '../page-image/PageImage';

import classes from './style.module.scss';

interface PageLayoutProps {
  src: string;
  objectPosition?: LazyImageProps['objectPosition'];
  title?: string;
  htmlTitle: string;
  subTitle?: string;
  children?: ReactNode;
  id: string;
  maxWidth?: BoxProps['maxWidth'];
  className?: BoxProps['className'];
  decorator?: DecoratorVariant;
  filterCity?: CityProps;
  description: SchemaOrgIterface['description'];
  image?: string;
  cannonicalPage?: string;
}

export const PageLayout: FC<PageLayoutProps> = ({
  src,
  title,
  htmlTitle,
  objectPosition,
  subTitle,
  children,
  id,
  maxWidth = '100%',
  className,
  decorator,
  filterCity,
  description,
  image,
  cannonicalPage,
}) => {
  const dispatch = useAppDispatch();

  const cityName = filterCity?.name.nominativus
    ? filterCity.name.nominativus
    : 'Olsztyn';
  const placeName = filterCity?.place
    ? filterCity.place
    : 'Województwo Warmińsko-Mazurskie';

  const location = useLocation();

  const breadcrumbList = useBreadcrumbs(location.pathname);

  dispatch(
    updateHeadFunc({
      description,
      title: htmlTitle,
      image: image ? image : src,
      preloadImg: src,
      cannonicalPage,
      areaServed: [
        {
          '@type': 'Place',
          name: cityName,
          additionalProperty: {
            '@type': 'PropertyValue',
            name: 'Typ',
            value: 'Miasto',
          },
        },
        {
          '@type': 'Place',
          name: placeName,
          additionalProperty: {
            '@type': 'PropertyValue',
            name: 'Typ',
            value: 'Województwo',
          },
        },
      ],
      serviceType: htmlTitle,
      breadcrumbList,
    })
  );

  return (
    <div key={location.pathname} className={classes.container} id={id}>
      <PageImage src={src} pageTitle={title} objectPosition={objectPosition} />
      <PageContent
        subTitle={subTitle}
        maxWidth={maxWidth}
        className={className}
        decorator={decorator}
      >
        {children}
      </PageContent>
    </div>
  );
};

export default memo(PageLayout);
