/* eslint-disable no-unused-vars */
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';

type SetValue<T> = Dispatch<SetStateAction<T>>;

function useLocalStorage<T>(
  key: string,
  initialValue: T
): [T, SetValue<T>, (value: T) => void] {
  const [storedValue, setStoredValue] = useState<T>(() => {
    // Get value from localStorage if it exists, otherwise use initialValue
    try {
      const item = window.localStorage.getItem(key);
      return item ? (JSON.parse(item) as T) : initialValue;
    } catch (error) {
      console.warn(`Error reading localStorage key “${key}”:`, error);
      return initialValue;
    }
  });

  const setValue = useCallback(
    (value: SetStateAction<T>) => {
      // Allow value to be a function so we have the same API as useState
      const newValue = value instanceof Function ? value(storedValue) : value;

      // Save the new value to localStorage
      try {
        window.localStorage.setItem(key, JSON.stringify(newValue));
        setStoredValue(newValue);

        // Dispatch a custom event to notify other instances of this hook
        window.dispatchEvent(new Event('local-storage'));
      } catch (error) {
        console.warn(`Error setting localStorage key “${key}”:`, error);
      }
    },
    [key, storedValue]
  );

  const updateLocalStorageValue = useCallback(
    (value: T) => {
      try {
        window.localStorage.setItem(key, JSON.stringify(value));
        setStoredValue(value);
        window.dispatchEvent(new Event('local-storage'));
      } catch (error) {
        console.warn(`Error updating localStorage key "${key}":`, error);
      }
    },
    [key]
  );

  const handleStorageChange = useCallback(
    (event: StorageEvent) => {
      if (event.key === key) {
        setStoredValue(
          event.newValue ? (JSON.parse(event.newValue) as T) : initialValue
        );
      }
    },
    [initialValue, key]
  );

  useEffect(() => {
    // Set up an event listener to listen for changes in localStorage
    window.addEventListener('storage', handleStorageChange);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [handleStorageChange]);

  return [storedValue, setValue, updateLocalStorageValue];
}

export default useLocalStorage;
