import { ContactForm } from '@components/contact-form/ContactForm';
import {
  Footer,
  MessengerChat,
  Snackbar,
  SummarySection,
} from '@components/index';
import Navbar from '@components/navbar/Navbar';

import { useAppSelector } from '@store/hooks';

import { ScrollToTop } from '@utils/scroll-to-top/ScrollToTop';
import { AnimatePresence } from 'framer-motion';

import { Outlet } from 'react-router-dom';

export const RootLayout = () => {
  const snackbarState = useAppSelector((state) => {
    return state.snackbarState;
  });
  return (
    <>
      <ScrollToTop />
      <Navbar />
      <main>
        <AnimatePresence mode="wait">
          <Outlet />
        </AnimatePresence>
      </main>
      <Snackbar
        status={snackbarState.status}
        message={snackbarState.message}
        timeout={snackbarState.timeout}
      />
      <SummarySection />
      <ContactForm />
      <Footer />
      <MessengerChat />
    </>
  );
};
