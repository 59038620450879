import { Snackbar } from '@components/index';
import { useAppSelector } from '@store/hooks';
import React from 'react';

import { Outlet } from 'react-router-dom';

export const AdminLayout = () => {
  const snackbarState = useAppSelector((state) => {
    return state.snackbarState;
  });
  return (
    <>
      <main>
        <Outlet />
      </main>
      <Snackbar
        status={snackbarState.status}
        message={snackbarState.message}
        timeout={snackbarState.timeout}
      />
    </>
  );
};
